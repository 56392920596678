import React, { useState, useEffect, useRef } from 'react'
import { Box, Typography } from '@mui/material'
import Cookies from 'js-cookie';
import Button from '@mui/material/Button';
import Header from "../header/Header";
import HeroSection from "../hero-section/HeroSection";
import FooterSection from "../footer/FooterSection";

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


import "../../App.css";
import "../chapter/Chapter.css";
import "../subject/Subject.css";
import "../responsive/Responsive.css";
import subjectIcon from '../../assets/images/science-biology.png';
import cardIcon from '../../assets/images/reproduction.png';

import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper';

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import 'swiper/css/navigation';
import 'swiper/css/scrollbar';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import ShareWithFriend from '../common-components/ShareWithFriend';
import MessageModal from '../common-components/MessageModal';
import QuickCallModal from '../common-components/QuickCallModal';
import FeedbackModal from '../common-components/FeedbackModal';

import useSocket from '../../custom-hooks/useSocket';
import useTranslations from '../../custom-hooks/useTranslations';
import useStudyMaterial from '../../custom-hooks/useStudyMaterial';
import useMockTest from '../../custom-hooks/useMockTest';
import useMockTestQuestions from '../../custom-hooks/useMockTestQuestions';
import { checkAnswer } from '../../actions/checkAnswerAction';
import { submitFeedBack } from '../../actions/submitFeedBackAction';
import { openMessageModal } from '../../actions/modalAction';

import generateAgoraToken from '../../services/AgoraTokenService';
import { encryptData } from '../../services/encryptDecrypt';
import SubTopHead from '../sub-top-head/SubTopHead';

const MockTestQuestions = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { subjectId, mockTestId, questionMark, questionChapterId } = useParams();
    const { socket, isConnected } = useSocket();
    const translations = useTranslations();
    const { getSubject } = useStudyMaterial();
    const subject = getSubject(atob(subjectId));
    const { getMockTest} = useMockTest(atob(subjectId));
    const mockTest = getMockTest(atob(mockTestId));
    const pathName = window.location.pathname.split('/')[1];
    const { getQuestionsByMark, getQuestionsByChapter, updateQuestionStatus, updateAttemptedQuestions } = useMockTestQuestions(atob(mockTestId));
    const mockTestQuestions = pathName === 'mock-test-by-mark' ? getQuestionsByMark(atob(questionMark)) : getQuestionsByChapter(atob(questionChapterId));
    const [questions, setQuestions] = useState(null);
    console.log("questions",questions);
    
    
    const [isActive, setIsActive] = useState(1);
    const [expandedQuestion, setExpandedQuestion] = useState(0);
    const [isAllExpanded, setIsAllExpanded] = useState(false);
    const [attemptedAnswer, setAttemptedAnswer] = useState([]);
    const [selectedOption, setSelectedOption] = useState({});
    const [correctOption, setCorrectOption] = useState({});
    const [isCheckSolution, setIsCheckSolution] = useState({});
    const [isApiCalling, setIsApiCalling] = useState(false);

    const [openModal, setOpenModal] = useState(false);
    const [isFeedback, setIsFeedback] = useState(sessionStorage.getItem('StudentSessionEnd') ? true : false);
    const [feedback, setFeedback] = useState({ TutorRating: '', FeedbackMessage: [] });

    const translation = {
        ...translations[21]?.CommonFrontend,
        ...translations[26]?.QuickCall,
        ...translations[36]?.PracticeTest
    }

    const studentProfile = Cookies.get("ProfileInfo") ? JSON.parse(Cookies.get("ProfileInfo")): null;
    const isMessage = useSelector(({ modalReducer }) => modalReducer.isMessage);

    // const handleBack = () => {
    //     const pathName = window.location.pathname.split('/')[2];
    //     if (pathName === "mock-test-by-mark") {
    //         navigate(`/mock-test-questions-detail/${subjectId}/${mockTestId}`)
    //     } else {
    //         navigate(`/mock-test-detail/${subjectId}/${mockTestId}`)
    //     }
    // }

    const filterQuestionHandler = (key, value, tab) => {
        setIsActive(tab ? tab : 1);
        if (key === 'IsAttempted' && value === false) {
            const filterQuestion = mockTestQuestions?.filter((question) => question[key] === undefined || question[key] === value )
            setQuestions(filterQuestion);
        } else if (key) {
            const filterQuestion = mockTestQuestions?.filter((question) => question[key] === value)
            setQuestions(filterQuestion);
        } else {
            setQuestions(mockTestQuestions);
        }

        if (isAllExpanded === false && key === 'IsAttempted' && value === true) {
            setIsAllExpanded(true);
        } else if (isAllExpanded === true && key !== 'IsAttempted' && value !== true) {
            setIsAllExpanded(false);
        }
    }

    const handleOptionClick = (questionId, optionId) => {
        if (correctOption[questionId] !== undefined) return;
      
        setSelectedOption((prev) => ({
          ...prev,
          [questionId]: optionId,
        }));
      
        setAttemptedAnswer((prev) => {
            const updatedAnswer = [...prev];
            const prevAnswerIndex = updatedAnswer.findIndex((question) => question.ID === questionId);
        
            if (prevAnswerIndex !== -1) {
              updatedAnswer[prevAnswerIndex] = { ...updatedAnswer[prevAnswerIndex], AnswerID: optionId };
            } else {
              updatedAnswer.push({ QuestionID: questionId, AnswerID: optionId });
            }
            
            sessionStorage.setItem('AnsweredQuestion', JSON.stringify(updatedAnswer))
            return updatedAnswer;
        });

        updateQuestionStatus(questionId, optionId)
    };
      
    
    const handleAnswerCheck = () => {
        const attemptedQuestion = mockTestQuestions?.filter((question) => question.IsAttempted === true)
        const correctAnswer =  attemptedQuestion?.reduce((acc, question) => {
            acc[question.ID] = question.AnswerID;
            return acc;
        }, {});
        const obj = {
            MockTestID: mockTest.ID,
            Scored: 0, 
            IsCompleted: false, 
            CompleteTime: 0,
            DataList: attemptedAnswer,
            IsMockTest: true
        }
        dispatch(checkAnswer(obj));
        setCorrectOption(correctAnswer || {});
        setAttemptedAnswer([]);
        updateAttemptedQuestions(attemptedAnswer)
        filterQuestionHandler("IsAttempted", true)
        setIsAllExpanded(true);
        setIsActive(2);
        sessionStorage.removeItem('AnsweredQuestion')
    }

    const handleCheckSolution = (question) => {
        setIsCheckSolution((prev) => ({
            ...prev,
            [question.ID]: prev[question.ID] === undefined ? true: !prev[question.ID]
        }));
    }

    const handleAccordianExpand = (questionIndex) => {
        if (expandedQuestion === questionIndex) {
            setExpandedQuestion(null);
        } else {
            setExpandedQuestion(questionIndex);
        }
    }


    const onChangeFeedback = (name, value) => {
        setFeedback((prev) => {
          if (name === 'FeedbackMessage') {
            const updatedFeedBack = prev?.FeedbackMessage?.includes(value)
              ? prev.FeedbackMessage.filter((item) => item !== value)
              : [...prev.FeedbackMessage, value];
      
            return {
              ...prev,
              FeedbackMessage: updatedFeedBack,
            };
          }
      
          return {
            ...prev,
            [name]: value,
          };
        });
    };

    const onSubmitFeedback = (e) => {
        setIsFeedback(false);
        const obj = {
			SessionID: sessionStorage.getItem('QuestionID'),
			TutorRating: feedback.TutorRating,
			SessionFeedbackMsg: feedback.FeedbackMessage
		}
		dispatch(submitFeedBack(obj))
        setFeedback({
            TutorRating: '',
            FeedbackMessage: []
        })
        sessionStorage.removeItem('StudentSessionEnd');
        sessionStorage.removeItem("StudentRequest");
        sessionStorage.removeItem("TutorResponse");
        sessionStorage.removeItem("QuestionID");
	}

    const handleAskTutor = (question) => {
        setOpenModal(true);
        setIsApiCalling(true);
		const payload = {
			QuestionID: 0,
			SubjectID: atob(subjectId),
			SubTopicID: "",
			SubjectName: mockTest?.SubjectName,
			Notes: question?.Question,
			ImageURL: '',
			StudentID: studentProfile?.ID,
			StudentName: studentProfile?.UserName,
			StudentImage: studentProfile?.ProfilePictureUrl,
			StudentClass: studentProfile?.Grade,
			StudentClassID: studentProfile?.StudentGradeID.toString(),
			TutorID: "",
			TutorName: "",
			TutorImage: "",
			TutorSubject: "",
			EnglishMessage: "",
			ArabicMessage: "",
			requestType: "1",
			ReasonID: "",
			IsAccept: false,
			IsDecline: false
		}

		const stringifiedObj = JSON.stringify(payload);
		socket.emit("QCQuestions", stringifiedObj, 'QC');
	};

    const cancelRequest = () => {
        const studentRequest = JSON.parse(sessionStorage.getItem('StudentRequest'));
		if (!socket || !socket.connected) {
			// If socket is not initialized or not connected, do not emit the event
			console.error('Socket is not connected. Unable to cancel request.');
			return;
		}
		studentRequest.IsDecline = true;
		studentRequest.requestType = "1";
		socket.emit("QCQuestions", JSON.stringify(studentRequest), 'QC');
        socket.off('GetOnlineTutorResponseEvent');
        sessionStorage.removeItem("TutorResponse")
        sessionStorage.removeItem("StudentRequest")
	};

    const closeModal = (event, reason) => {
		if (reason && reason === "backdropClick" && "escapeKeyDown") {
			return;
		}
		setOpenModal(false);
		cancelRequest();
	};

    // Helper function to format the question content
    const formatQuestionContent = (content) => {
        // Check if the content contains an SVG encoded inline (data URL)
        if (content?.includes('data:image/svg+xml')) {
        return content; // Directly return the SVG inline data as HTML
        } else if (content?.includes('https://storage.googleapis.com/')) {
        // Replace image URLs with an <img> tag if it's an external SVG URL
        return content?.replace(/(https:\/\/storage\.googleapis\.com\/[\w\W]+\.svg)/g, 
            `<img src="$1" alt="Question Image" style="max-width: 100%; height: auto;" />`);
        }
        return content; // Return the original content if no SVG image URLs are found
    };

    useEffect(() => {
        if (Array.isArray(mockTestQuestions) && questions === null) {
            const attemptedQuestion = mockTestQuestions.filter((question) => question.IsAttempted === true);
            const checkedQuestion = mockTestQuestions.filter((question) => question.IsAnswerChecked === true);
            const attemptedNotChecked = mockTestQuestions.filter((question) => question.IsAttempted === true && question.IsAnswerChecked === undefined );

            const correctAnswer =  checkedQuestion?.reduce((acc, question) => {
                acc[question.ID] = question.AnswerID;
                return acc;
            }, {});

            const selectAnswer =  attemptedQuestion?.reduce((acc, question) => {
                acc[question.ID] = question.LastSelectedAnswerID;
                return acc;
            }, {});

            setQuestions(mockTestQuestions);
            setCorrectOption(correctAnswer || {});
            setSelectedOption(selectAnswer || {});
            setAttemptedAnswer(attemptedNotChecked);
        }
    }, [mockTestQuestions]);

    useEffect(() => {
		if (isConnected) {
			socket.on("QCQuestions", (data) => {
				const response = JSON.parse(data);

				if (response?.StatusCode === 200) {
					const QuestionQCData = response?.Data
					if (QuestionQCData?.requestType == "1" && QuestionQCData?.IsDecline == false) {
						// setOpenModal(true);
                        setIsApiCalling(false);
                        sessionStorage.setItem('StudentRequest', JSON.stringify(response?.Data))

                        const payload = {}
                        payload["langid"] = localStorage.getItem("langId");
                        // payload['DeviceID'] = sessionStorage.getItem('deviceId');
                        payload['DeviceID'] = Cookies.get('deviceId');
                        payload['authorization'] = JSON.parse(Cookies.get('loginToken'))?.idToken;
                        payload["StudentID"] = response?.Data?.StudentID;
                        payload["SubjectID"] = response?.Data?.SubjectID;
                        payload["SessionID"] = response?.Data?.QuestionID?.toString();

                        socket?.emit('GetOnlineTutor', JSON.stringify(payload));
                        
					} else if (QuestionQCData.requestType == "2" && QuestionQCData.IsAccept == true) {
                        const studentRequest = JSON.parse(sessionStorage.getItem('StudentRequest'));
						if (parseInt(studentRequest?.QuestionID) === parseInt(QuestionQCData?.QuestionID)) {
                            sessionStorage.setItem('pathName', window.location.pathname);
							sessionStorage.setItem('QuestionID', studentRequest?.QuestionID);
							sessionStorage.setItem('TutorResponse', JSON.stringify(QuestionQCData))
							const userUid = studentProfile?.UID;
							const channelName = `channel${studentRequest?.QuestionID}`;
							generateAgoraToken(userUid, 2, channelName).then((res) => {
								localStorage.setItem("rtmToken", JSON.stringify(res.RtmToken));
								localStorage.setItem("rtcToken", JSON.stringify(res.RtcToken));
								navigate(`/quick-calls-room/${encryptData(studentRequest?.QuestionID)}`);
							});
						}
					}
				} else if (response.StatusCode === 400) {
                    setOpenModal(false);
                    dispatch(openMessageModal(translation?.ExceededFreeCallLimit))
				} else if (response.StatusCode === 402) {
                    setOpenModal(false);
					dispatch(openMessageModal(translation?.LowAmountMessage))
				} else {
                    setOpenModal(false);
                }
			})
		}

	}, [isConnected])

  return (
    <div>
         <Header />
            <HeroSection />
            <Box className='OuterContainer'>
                <Box className='studyContainer'>
                    <SubTopHead subject={subject} />
                    {/* <Box className='subHeading'>
                        <Box className="cardMain lightGray">
                            <Box className="BackBtn">
                                <Button className='backBtn' variant="text" sx={{ mb: 1, paddingLeft: 0 }} startIcon={<ArrowBackIcon />} onClick={handleBack}> {translation?.BackBtn} </Button>
                            </Box>

                            <Box className="cardTop" sx={{ display: { xs: 'flex', alignItems: 'center', justifyContent: 'space-between' } }}>
                                <Box className="cardRight" sx={{ display: { xs: 'flex', alignItems: 'center' } }}>
                                <Box className="cardIcon">
                                <img src={subject?.SubjectImageURL} alt=""></img>
                                </Box>
                                    <Box className="cardContent">
                                        <Typography variant='h4'>{subject?.SubjectName}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box> */}

                    <Box className="practiceBox">
                        <Box className='practiceBoxIn'>
                            <ShareWithFriend />

                            <Box className="solutionBanner lightYellow" sx={{ mt: 3, mb: 0, display: { xs: 'flex', alignItems: 'center', justifyContent: 'space-between' } }}>
                                <Box className="contentBox">
                                    <Typography>
                                        {mockTest?.TestName}
                                    </Typography>
                                    {/* <Typography variant='h3' sx={{ margin: 0 }} >
                                        reproduction in organisms
                                    </Typography> */}
                                    <Box className="cardList">
                                        <ul>
                                            <li>{mockTest?.NumberOfQuestion} {translation?.PracticeQuestion} </li>
                                            {/* <li>{questionsByChapter?.length} topics</li> */}
                                        </ul>
                                    </Box>
                                </Box>
                                <Box className="imageBox">
                                    <img src={cardIcon}></img>
                                </Box>
                            </Box>
                            <Box className="attemptedHeading" sx={{ background: '#FFEBCE', padding: 2 }}>
                                <Box sx={{ mb:1, display: { xs: 'flex', alignItems: 'center' } }}>
                                    {/* <Typography>
                                        <Typography variant='div'>overview of preproduction and life span</Typography>
                                    </Typography> */}
                                </Box>
                                
                                <Box className="FilterBtnBox">
                                    <Swiper
                                        modules={[Pagination,A11y]}
                                        spaceBetween={0}
                                        slidesPerView={3}
                                        pagination={{ clickable: true }}
                                        // navigation={true}
                                        // modules={[Autoplay]}
                                        // onSlideChange={() => console.log("slide change")}
                                        // onSwiper={(swiper) => console.log(swiper)}
                                        scrollbar={{ draggable: true }}
                                        // onSlideChange={(swiper) => console.log(swiper)}
                                        breakpoints={{
                                            0: {
                                                slidesPerView:1.5,
                                                spaceBetween:10,
                                            },
                                            480: {
                                            slidesPerView:2.5,
                                            spaceBetween:10,
                                            },
                                            768: {
                                                slidesPerView:3,
                                                spaceBetween:10,
                                            },
                                        }}
                                    >
                                        <SwiperSlide className={`${isActive === 1 ? 'swiperslide-active' : ''}`}>
                                            <Button variant="outlined" onClick={() => filterQuestionHandler()}>
                                                {translation?.AllQuestions} ({mockTestQuestions?.length})
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide className={`${isActive === 2 ? 'swiperslide-active' : ''}`}>
                                            <Button variant="outlined" onClick={() => filterQuestionHandler("IsAttempted", true, 2)}>
                                                {translation?.PracticeAttempted} ({mockTestQuestions?.reduce((acc, curr) => curr.IsAttempted === true ? acc = acc +  1 : acc, 0)})
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide className={`${isActive === 3 ? 'swiperslide-active' : ''}`}>
                                            <Button variant="outlined" onClick={() => filterQuestionHandler("IsAttempted", false, 3)}>
                                                {translation?.PracticeNotAttempted} ({mockTestQuestions?.reduce((acc, curr) => !curr.IsAttempted ? acc = acc +  1 : acc, 0)})
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide className={`${isActive === 4 ? 'swiperslide-active' : ''}`}>
                                            <Button variant="outlined" onClick={() => filterQuestionHandler("IsCorrect", true, 4)}>
                                                {translation?.PracticeCorrect} ({mockTestQuestions?.reduce((acc, current) => current?.IsCorrect === true ? acc = acc + 1 : acc, 0)})
                                            </Button>
                                        </SwiperSlide>
                                        <SwiperSlide className={`${isActive === 5 ? 'swiperslide-active' : ''}`}>
                                            <Button variant="outlined" onClick={() => filterQuestionHandler("IsCorrect", false, 5)}>
                                                {translation?.PracticeIncorrect} ({mockTestQuestions?.reduce((acc, current) => current?.IsCorrect === false ? acc = acc + 1 : acc, 0)})
                                            </Button>
                                        </SwiperSlide>
                                    </Swiper>

                                    {/* <Button variant="outlined">All Questions (10)</Button>
                                    <Button variant="outlined">Attempted (04)</Button>
                                    <Button variant="outlined">Not Attempted (06)</Button>
                                    <Button variant="outlined">Understood (02)</Button>
                                    <Button variant="outlined">Not Understood (10)</Button> */}
                                </Box>
                            </Box>

                            <Box className="mockQuestion">
                                <Box className="mainHeading" sx={{mt:2,mb:1}}>
                                    <Typography variant='h3'>
                                        {translation?.PracticeQuestion}
                                    </Typography>
                                </Box>

                                <Box className="QuestionAccordion">
                                { questions?.length > 0 && 
                                    (questions || []).map((question, questionIndex) => {
                                        return <Accordion expanded={questionIndex === expandedQuestion || isAllExpanded ? true: false} key={question?.ID}> 
                                        <AccordionSummary 
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content" id="panel1-header"
                                        onClick={() => handleAccordianExpand(questionIndex)}
                                        >
                                        <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                            <Box className="cardIcon">{questionIndex + 1}</Box>
                                            {/* <Box className="cardContent"><Typography variant='h4'>{<InlineMath math={question?.Question} />}</Typography></Box> */}
                                            <Box className="cardContent">
                                                <Typography variant="h4" 
                                                            dangerouslySetInnerHTML={{ __html: formatQuestionContent(question?.Question) }} />
                                            </Box>                                       
                                        </Box>
                                        </AccordionSummary>
                                        <AccordionDetails >
                                        <Box className="Classification">
                                            <Box className="selectOption">
                                                <Typography sx={{mb:2}}>
                                                    {translation?.SelectOneOption}
                                                </Typography>
                                                <Box className="questioList" >
                                                    { question?.Options?.map((option, optionIndex) => {
                                                        const isSelected = selectedOption[question.ID] === option.ID && correctOption[question.ID] === undefined;
                                                        const isCorrect = correctOption[question.ID] === option.ID && correctOption[question.ID] === selectedOption[question.ID];
                                                        const isCorrectAnswer = correctOption[question.ID] === option.ID;
                                                        const isIncorrect = correctOption[question.ID] !== undefined && selectedOption[question.ID] === option.ID && selectedOption[question.ID] !== correctOption[question.ID];

                                                        const className = isSelected ? 'blue' : isIncorrect ? 'pink' : isCorrect ? 'green' : isCorrectAnswer ? 'blue' : '';

                                                        return <Box
                                                            className={`questionListIn ${className}`}
                                                            sx={{ mb: 1, display: 'flex', alignItems: 'center' }}
                                                            key={option?.ID}
                                                            onClick={() => handleOptionClick(question?.ID, option?.ID)}
                                                        >
                                                            <Box className="cardIcon">{String.fromCharCode(65 + optionIndex)}</Box>
                                                            <Box>
                                                                <Typography dangerouslySetInnerHTML={{__html: formatQuestionContent(option?.Value)}}></Typography>
                                                                {isIncorrect && (
                                                                    <Typography variant="div">
                                                                        {translation?.WrongAnswer}
                                                                    </Typography>
                                                                )}
                                                                {(isCorrect || isCorrectAnswer) && selectedOption[question.ID] !== undefined && (
                                                                    <Typography variant="div">
                                                                        {translation?.CorrectAnswer}
                                                                    </Typography>
                                                                )}
                                                            </Box>
                                                        </Box>
                                                    }) }

                                                    {/* <Box className="questionListIn green" sx={{mb:1, display: { xs: 'flex', alignItems: 'center', }}}>
                                                        <Box className="cardIcon">A</Box>
                                                        <Typography>
                                                        Biological process of producing young ones
                                                        <Typography variant='div'>
                                                        correct answer
                                                            </Typography>
                                                        </Typography>
                                                    </Box>
                                                    <Box className="questionListIn" sx={{mb:1,  display: { xs: 'flex', alignItems: 'center', }}}>
                                                        <Box className="cardIcon">B</Box>
                                                        <Typography>
                                                        Non-biological process of young ones
                                                        </Typography>
                                                    </Box>
                                                    <Box className="questionListIn pink" sx={{mb:1,  display: { xs: 'flex', alignItems: 'center', }}}>
                                                        <Box className="cardIcon">C</Box>
                                                        <Typography>
                                                        Biological process of producing mature ones
                                                            <Typography variant='div'>
                                                            wrong answer
                                                            </Typography>
                                                        </Typography>
                                                    </Box>
                                                    <Box className="questionListIn" sx={{  display: { xs: 'flex', alignItems: 'center', }}}>
                                                        <Box className="cardIcon">D</Box>
                                                        <Typography>
                                                        None of above
                                                        </Typography>
                                                    </Box> */}
                                                </Box>

                                                { correctOption[question.ID] !== undefined && !isCheckSolution[question.ID] && <>
                                                    <Box className="QuestionSolution" sx={{mt:3}}>
                                                        <Box className="cardMain orgbdr lightYellow">
                                                            <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                                                <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                                                    <Box className="cardIcon YellowCircle">
                                                                    <Typography variant='div'>
                                                                    <svg width="34" height="34" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/  svg">
                                                                    <path d="M8.51625 3.00781L6.75625 4.76531L9.375 7.38281L11.1325 5.62531L8.51625 3.00781ZM31.485 3.00781L28.8663 5.62531L30.625 7.38281L33.2425 4.76656L31.485 3.00781ZM20 3.78781C19.5875 3.79281 19.17 3.81656 18.75 3.86781C18.7375 3.86781 18.725 3.86531 18.7125 3.86781C13.6425 4.44906 9.6075 8.54531 8.90625 13.5928C8.34625 17.6553 10.0212 21.3391 12.8125 23.7116C13.9543 24.686 14.7265 26.0231 15 27.4991V34.9991H17.85C18.285 35.7453 19.0788 36.2491 20 36.2491C20.9212 36.2491 21.715 35.7453 22.15 34.9991H25V29.9991H25.1175V28.5141C25.1175 26.6816 26.07 24.8341 27.6175 23.3978C29.6875 21.3253 31.25 18.3816 31.25 15.0003C31.25 8.82531 26.1675 3.73031 20 3.78781ZM20 6.28781C24.8312 6.22031 28.75 10.1753 28.75 15.0003C28.75 17.6178 27.5375 19.9228 25.8587 21.6003L25.8988 21.6403C24.2305 23.1787 23.1517 25.2513 22.8487 27.5003H17.3812C17.1062 25.3578 16.1875 23.2841 14.4513 21.7966C12.2425 19.9216 10.9263 17.0966 11.3663 13.9066C11.9125 9.96906 15.1075 6.80281 19.0225 6.36906C19.3457 6.324 19.6712 6.29729 19.9975 6.28906L20 6.28781ZM2.5 15.0003V17.5003H6.25V15.0003H2.5ZM33.75 15.0003V17.5003H37.5V15.0003H33.75ZM9.375 25.1178L6.7575 27.7341L8.51625 29.4928L11.1312 26.8753L9.375 25.1178ZM30.625 25.1178L28.8675 26.8753L31.4837 29.4928L33.2425 27.7341L30.625 25.1178ZM17.5 30.0003H22.5V32.5003H17.5V30.0003Z" fill="black"/>
                                                                    </svg>
                                                                    </Typography>
                                                                    </Box>
                                                                    <Box className="cardContent">
                                                                        <Typography variant='h4'>{translation?.PracticeSolutions}</Typography>
                                                                    </Box>
                                                                    
                                                                </Box>
                                                                <Box className="cardLeft">
                                                                    <Button variant="contained" className='darkYellow' onClick={() => handleCheckSolution(question)}>{translation?.CheckSolution}</Button>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </> }

                                    

                                                { isCheckSolution[question.ID] && <>
                                                    <Box className="rightSolution orgbdr radius8">
                                                        <Box className="bdrDash solutionSmall" sx={{display:{xs: 'flex', alignItems: 'center'}}}>
                                                            <Typography variant='div' className='YellowCircle' sx={{mr:1}}>
                                                            <svg width="28" height="28" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/  svg">
                                                                <path d="M8.51625 3.00781L6.75625 4.76531L9.375 7.38281L11.1325 5.62531L8.51625 3.00781ZM31.485 3.00781L28.8663 5.62531L30.625 7.38281L33.2425 4.76656L31.485 3.00781ZM20 3.78781C19.5875 3.79281 19.17 3.81656 18.75 3.86781C18.7375 3.86781 18.725 3.86531 18.7125 3.86781C13.6425 4.44906 9.6075 8.54531 8.90625 13.5928C8.34625 17.6553 10.0212 21.3391 12.8125 23.7116C13.9543 24.686 14.7265 26.0231 15 27.4991V34.9991H17.85C18.285 35.7453 19.0788 36.2491 20 36.2491C20.9212 36.2491 21.715 35.7453 22.15 34.9991H25V29.9991H25.1175V28.5141C25.1175 26.6816 26.07 24.8341 27.6175 23.3978C29.6875 21.3253 31.25 18.3816 31.25 15.0003C31.25 8.82531 26.1675 3.73031 20 3.78781ZM20 6.28781C24.8312 6.22031 28.75 10.1753 28.75 15.0003C28.75 17.6178 27.5375 19.9228 25.8587 21.6003L25.8988 21.6403C24.2305 23.1787 23.1517 25.2513 22.8487 27.5003H17.3812C17.1062 25.3578 16.1875 23.2841 14.4513 21.7966C12.2425 19.9216 10.9263 17.0966 11.3663 13.9066C11.9125 9.96906 15.1075 6.80281 19.0225 6.36906C19.3457 6.324 19.6712 6.29729 19.9975 6.28906L20 6.28781ZM2.5 15.0003V17.5003H6.25V15.0003H2.5ZM33.75 15.0003V17.5003H37.5V15.0003H33.75ZM9.375 25.1178L6.7575 27.7341L8.51625 29.4928L11.1312 26.8753L9.375 25.1178ZM30.625 25.1178L28.8675 26.8753L31.4837 29.4928L33.2425 27.7341L30.625 25.1178ZM17.5 30.0003H22.5V32.5003H17.5V30.0003Z" fill="black"/>
                                                                </svg>
                                                            </Typography>
                                                            <Typography  sx={{margin:0}}>
                                                                {translation?.PracticeSolutions}
                                                            </Typography>
                                                        </Box>
                                                        <Box className="SolutionSummary">
                                                            <Typography variant='h3' sx={{mb:1}}>
                                                                {translation?.CorrectOption} {String.fromCharCode(65 + question?.Options.findIndex((option) => option.ID === correctOption[question.ID]))}
                                                            </Typography>
                                                            <Typography dangerouslySetInnerHTML={{__html: question?.Solution}}></Typography>
                                                        </Box>
                                                    </Box>
                                                </> }

                                                { correctOption[question.ID] !== undefined && 
                                                    <Box className="tutorBtnBox" sx={{mt:2}}>
                                                        <button className='tuterBtn' sx={{display:{xs: 'flex', alignItems: 'center', justifyContent:'center'}}} onClick={() => handleAskTutor(question)}>
                                                            <Typography variant='div' sx={{display:{xs: 'flex', alignItems: 'center', justifyContent:'center'}}}>
                                                            <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="30" height="30" rx="15" transform="matrix(-1 0 0 1 30 0)" fill="white"/>
                                                            <path d="M9.17493 21.4788L10.4634 18.782C10.7407 18.2058 11.0992 17.7864 11.718 18.0421L12.9388 18.499C13.9162 18.9558 14.4031 18.499 14.8935 17.8103L17.0916 12.7677C17.3689 12.1915 17.2235 11.6494 16.6047 11.3937L14.8969 10.705C14.278 10.4458 14.3761 9.90714 14.6534 9.33095L16.1177 6.58292C16.395 6.00673 16.9631 5.86694 17.582 6.12265C18.8197 6.63748 19.8444 7.44552 20.5106 8.64224C21.3222 10.1049 20.9164 12.1403 20.7541 12.9961C20.5917 13.8519 20.0236 15.352 19.2898 16.8897C18.5559 18.4308 17.9134 19.6411 17.3351 20.3264C16.7602 21.0117 15.3805 22.8869 13.6727 23.3063C12.2726 23.6473 10.761 23.3609 9.52326 22.846C8.90101 22.6005 8.90101 22.0584 9.17493 21.4788Z" fill="#005DB8"/>
                                                            </svg>
                                                                {translation?.PractionAskTutor}
                                                            </Typography>
                                                        </button>
                                                    </Box>
                                                }
                                            </Box>
                                        </Box>
                                        </AccordionDetails>
                                    </Accordion>

                                    }) 
                                }
                                    {/* <Accordion defaultExpanded> 
                                        <AccordionSummary 
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1-content" id="panel1-header"
                                        >
                                        <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                            <Box className="cardIcon">1</Box>
                                            <Box className="cardContent"><Typography variant='h4'>reproduction is</Typography></Box>
                                        </Box>
                                        </AccordionSummary>
                                        <AccordionDetails >
                                        <Box className="Classification">
                                            <Box className="selectOption">
                                                <Typography sx={{mb:2}}>
                                                    select one option
                                                </Typography>
                                                <Box className="questioList" >
                                                    <Box className="questionListIn green" sx={{mb:1, display: { xs: 'flex', alignItems: 'center', }}}>
                                                        <Box className="cardIcon">A</Box>
                                                        <Typography>
                                                        Biological process of producing young ones
                                                        <Typography variant='div'>
                                                        correct answer
                                                            </Typography>
                                                        </Typography>
                                                    </Box>
                                                    <Box className="questionListIn" sx={{mb:1,  display: { xs: 'flex', alignItems: 'center', }}}>
                                                        <Box className="cardIcon">B</Box>
                                                        <Typography>
                                                        Non-biological process of young ones
                                                        </Typography>
                                                    </Box>
                                                    <Box className="questionListIn pink" sx={{mb:1,  display: { xs: 'flex', alignItems: 'center', }}}>
                                                        <Box className="cardIcon">C</Box>
                                                        <Typography>
                                                        Biological process of producing mature ones
                                                            <Typography variant='div'>
                                                            wrong answer
                                                            </Typography>
                                                        </Typography>
                                                    </Box>
                                                    <Box className="questionListIn" sx={{  display: { xs: 'flex', alignItems: 'center', }}}>
                                                        <Box className="cardIcon">D</Box>
                                                        <Typography>
                                                        None of above
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                                <Box className="QuestionSolution" sx={{mt:3}}>
                                                    <Box className="cardMain orgbdr lightYellow">
                                                        <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                                            <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                                                <Box className="cardIcon YellowCircle">
                                                                <Typography variant='div'>
                                                                <svg width="34" height="34" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/  svg">
                                                                <path d="M8.51625 3.00781L6.75625 4.76531L9.375 7.38281L11.1325 5.62531L8.51625 3.00781ZM31.485 3.00781L28.8663 5.62531L30.625 7.38281L33.2425 4.76656L31.485 3.00781ZM20 3.78781C19.5875 3.79281 19.17 3.81656 18.75 3.86781C18.7375 3.86781 18.725 3.86531 18.7125 3.86781C13.6425 4.44906 9.6075 8.54531 8.90625 13.5928C8.34625 17.6553 10.0212 21.3391 12.8125 23.7116C13.9543 24.686 14.7265 26.0231 15 27.4991V34.9991H17.85C18.285 35.7453 19.0788 36.2491 20 36.2491C20.9212 36.2491 21.715 35.7453 22.15 34.9991H25V29.9991H25.1175V28.5141C25.1175 26.6816 26.07 24.8341 27.6175 23.3978C29.6875 21.3253 31.25 18.3816 31.25 15.0003C31.25 8.82531 26.1675 3.73031 20 3.78781ZM20 6.28781C24.8312 6.22031 28.75 10.1753 28.75 15.0003C28.75 17.6178 27.5375 19.9228 25.8587 21.6003L25.8988 21.6403C24.2305 23.1787 23.1517 25.2513 22.8487 27.5003H17.3812C17.1062 25.3578 16.1875 23.2841 14.4513 21.7966C12.2425 19.9216 10.9263 17.0966 11.3663 13.9066C11.9125 9.96906 15.1075 6.80281 19.0225 6.36906C19.3457 6.324 19.6712 6.29729 19.9975 6.28906L20 6.28781ZM2.5 15.0003V17.5003H6.25V15.0003H2.5ZM33.75 15.0003V17.5003H37.5V15.0003H33.75ZM9.375 25.1178L6.7575 27.7341L8.51625 29.4928L11.1312 26.8753L9.375 25.1178ZM30.625 25.1178L28.8675 26.8753L31.4837 29.4928L33.2425 27.7341L30.625 25.1178ZM17.5 30.0003H22.5V32.5003H17.5V30.0003Z" fill="black"/>
                                                                </svg>
                                                                </Typography>
                                                                </Box>
                                                                <Box className="cardContent">
                                                                    <Typography variant='h4'>solution   </Typography>
                                                                </Box>
                                                                
                                                            </Box>
                                                            <Box className="cardLeft">
                                                                <Button variant="contained" className='darkYellow'>check solution</Button>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>

                                                <Box className="understoodBox">
                                                    <Typography sx={{mt:1, mb:2, display:{xs: 'flex', alignItems: 'center'}}}>
                                                        <Typography variant='div' sx={{mr:1}}>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="12" cy="12" r="11" fill="#005DB8"/>
                                                            <path d="M10.243 16.3151L6 12.0711L7.414 10.6571L10.243 13.4851L15.899 7.82812L17.314 9.24312L10.243 16.3151Z" fill="white"/>
                                                            </svg>
                                                        </Typography>
                                                    understood
                                                    </Typography>
                                                </Box>

                                                <Box className="rightSolution orgbdr radius8">
                                                    <Box className="bdrDash solutionSmall" sx={{display:{xs: 'flex', alignItems: 'center'}}}>
                                                        <Typography variant='div' className='YellowCircle' sx={{mr:1}}>
                                                        <svg width="28" height="28" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/  svg">
                                                            <path d="M8.51625 3.00781L6.75625 4.76531L9.375 7.38281L11.1325 5.62531L8.51625 3.00781ZM31.485 3.00781L28.8663 5.62531L30.625 7.38281L33.2425 4.76656L31.485 3.00781ZM20 3.78781C19.5875 3.79281 19.17 3.81656 18.75 3.86781C18.7375 3.86781 18.725 3.86531 18.7125 3.86781C13.6425 4.44906 9.6075 8.54531 8.90625 13.5928C8.34625 17.6553 10.0212 21.3391 12.8125 23.7116C13.9543 24.686 14.7265 26.0231 15 27.4991V34.9991H17.85C18.285 35.7453 19.0788 36.2491 20 36.2491C20.9212 36.2491 21.715 35.7453 22.15 34.9991H25V29.9991H25.1175V28.5141C25.1175 26.6816 26.07 24.8341 27.6175 23.3978C29.6875 21.3253 31.25 18.3816 31.25 15.0003C31.25 8.82531 26.1675 3.73031 20 3.78781ZM20 6.28781C24.8312 6.22031 28.75 10.1753 28.75 15.0003C28.75 17.6178 27.5375 19.9228 25.8587 21.6003L25.8988 21.6403C24.2305 23.1787 23.1517 25.2513 22.8487 27.5003H17.3812C17.1062 25.3578 16.1875 23.2841 14.4513 21.7966C12.2425 19.9216 10.9263 17.0966 11.3663 13.9066C11.9125 9.96906 15.1075 6.80281 19.0225 6.36906C19.3457 6.324 19.6712 6.29729 19.9975 6.28906L20 6.28781ZM2.5 15.0003V17.5003H6.25V15.0003H2.5ZM33.75 15.0003V17.5003H37.5V15.0003H33.75ZM9.375 25.1178L6.7575 27.7341L8.51625 29.4928L11.1312 26.8753L9.375 25.1178ZM30.625 25.1178L28.8675 26.8753L31.4837 29.4928L33.2425 27.7341L30.625 25.1178ZM17.5 30.0003H22.5V32.5003H17.5V30.0003Z" fill="black"/>
                                                            </svg>
                                                        </Typography>
                                                        <Typography  sx={{margin:0}}>
                                                            solution
                                                        </Typography>
                                                    </Box>
                                                    <Box className="SolutionSummary">
                                                        <Typography variant='h3' sx={{mb:1}}>
                                                        The correct option is D
                                                        </Typography>
                                                        <Typography>
                                                        As we can see in the diagram there are 1.5 million males and 1.5 million females under the age group 15 which is largest as compared to the other age groups.
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                                <Box className="tutorBtnBox" sx={{mt:2}}>
                                                    <button className='tuterBtn' sx={{display:{xs: 'flex', alignItems: 'center', justifyContent:'center'}}}>
                                                        <Typography variant='div' sx={{display:{xs: 'flex', alignItems: 'center', justifyContent:'center'}}}>
                                                        <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <rect width="30" height="30" rx="15" transform="matrix(-1 0 0 1 30 0)" fill="white"/>
                                                        <path d="M9.17493 21.4788L10.4634 18.782C10.7407 18.2058 11.0992 17.7864 11.718 18.0421L12.9388 18.499C13.9162 18.9558 14.4031 18.499 14.8935 17.8103L17.0916 12.7677C17.3689 12.1915 17.2235 11.6494 16.6047 11.3937L14.8969 10.705C14.278 10.4458 14.3761 9.90714 14.6534 9.33095L16.1177 6.58292C16.395 6.00673 16.9631 5.86694 17.582 6.12265C18.8197 6.63748 19.8444 7.44552 20.5106 8.64224C21.3222 10.1049 20.9164 12.1403 20.7541 12.9961C20.5917 13.8519 20.0236 15.352 19.2898 16.8897C18.5559 18.4308 17.9134 19.6411 17.3351 20.3264C16.7602 21.0117 15.3805 22.8869 13.6727 23.3063C12.2726 23.6473 10.761 23.3609 9.52326 22.846C8.90101 22.6005 8.90101 22.0584 9.17493 21.4788Z" fill="#005DB8"/>
                                                        </svg>
                                                            Ask Tutor
                                                        </Typography>
                                                    </button>
                                                </Box>
                                            </Box>
                                        </Box>
                                        </AccordionDetails>
                                    </Accordion> */}

                                    {/* <Accordion>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel2-content"
                                        id="panel2-header"
                                        >
                                        <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                            <Box className="cardIcon">2</Box>
                                            <Box className="cardContent"><Typography variant='h4'>which one can't be included under basic features of reproduction ?</Typography></Box>
                                        </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <Box className="Classification">
                                            <Box className="selectOption">
                                                <Typography sx={{mb:2}}>
                                                    select one option
                                                </Typography>
                                                <Box className="questioList" >
                                                    <Box className="questionListIn green" sx={{mb:1, display: { xs: 'flex', alignItems: 'center', }}}>
                                                        <Box className="cardIcon">A</Box>
                                                        <Typography>
                                                        Biological process of producing young ones
                                                        <Typography variant='div'>
                                                        correct answer
                                                            </Typography>
                                                        </Typography>
                                                    </Box>
                                                    <Box className="questionListIn" sx={{mb:1,  display: { xs: 'flex', alignItems: 'center', }}}>
                                                        <Box className="cardIcon">B</Box>
                                                        <Typography>
                                                        Non-biological process of young ones
                                                        </Typography>
                                                    </Box>
                                                    <Box className="questionListIn pink" sx={{mb:1,  display: { xs: 'flex', alignItems: 'center', }}}>
                                                        <Box className="cardIcon">C</Box>
                                                        <Typography>
                                                        Biological process of producing mature ones
                                                            <Typography variant='div'>
                                                            wrong answer
                                                            </Typography>
                                                        </Typography>
                                                    </Box>
                                                    <Box className="questionListIn" sx={{  display: { xs: 'flex', alignItems: 'center', }}}>
                                                        <Box className="cardIcon">D</Box>
                                                        <Typography>
                                                        None of above
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                                <Box className="QuestionSolution" sx={{mt:3}}>
                                                    <Box className="cardMain orgbdr lightYellow">
                                                        <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                                            <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                                                <Box className="cardIcon YellowCircle">
                                                                <Typography variant='div'>
                                                                <svg width="34" height="34" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/  svg">
                                                                <path d="M8.51625 3.00781L6.75625 4.76531L9.375 7.38281L11.1325 5.62531L8.51625 3.00781ZM31.485 3.00781L28.8663 5.62531L30.625 7.38281L33.2425 4.76656L31.485 3.00781ZM20 3.78781C19.5875 3.79281 19.17 3.81656 18.75 3.86781C18.7375 3.86781 18.725 3.86531 18.7125 3.86781C13.6425 4.44906 9.6075 8.54531 8.90625 13.5928C8.34625 17.6553 10.0212 21.3391 12.8125 23.7116C13.9543 24.686 14.7265 26.0231 15 27.4991V34.9991H17.85C18.285 35.7453 19.0788 36.2491 20 36.2491C20.9212 36.2491 21.715 35.7453 22.15 34.9991H25V29.9991H25.1175V28.5141C25.1175 26.6816 26.07 24.8341 27.6175 23.3978C29.6875 21.3253 31.25 18.3816 31.25 15.0003C31.25 8.82531 26.1675 3.73031 20 3.78781ZM20 6.28781C24.8312 6.22031 28.75 10.1753 28.75 15.0003C28.75 17.6178 27.5375 19.9228 25.8587 21.6003L25.8988 21.6403C24.2305 23.1787 23.1517 25.2513 22.8487 27.5003H17.3812C17.1062 25.3578 16.1875 23.2841 14.4513 21.7966C12.2425 19.9216 10.9263 17.0966 11.3663 13.9066C11.9125 9.96906 15.1075 6.80281 19.0225 6.36906C19.3457 6.324 19.6712 6.29729 19.9975 6.28906L20 6.28781ZM2.5 15.0003V17.5003H6.25V15.0003H2.5ZM33.75 15.0003V17.5003H37.5V15.0003H33.75ZM9.375 25.1178L6.7575 27.7341L8.51625 29.4928L11.1312 26.8753L9.375 25.1178ZM30.625 25.1178L28.8675 26.8753L31.4837 29.4928L33.2425 27.7341L30.625 25.1178ZM17.5 30.0003H22.5V32.5003H17.5V30.0003Z" fill="black"/>
                                                                </svg>
                                                                </Typography>
                                                                </Box>
                                                                <Box className="cardContent">
                                                                    <Typography variant='h4'>solution   </Typography>
                                                                </Box>
                                                                
                                                            </Box>
                                                            <Box className="cardLeft">
                                                                <Button variant="contained" className='darkYellow'>check solution</Button>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>

                                                <Box className="understoodBox">
                                                    <Typography sx={{mt:1, mb:2, display:{xs: 'flex', alignItems: 'center'}}}>
                                                        <Typography variant='div' sx={{mr:1}}>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="12" cy="12" r="11" fill="#005DB8"/>
                                                            <path d="M10.243 16.3151L6 12.0711L7.414 10.6571L10.243 13.4851L15.899 7.82812L17.314 9.24312L10.243 16.3151Z" fill="white"/>
                                                            </svg>
                                                        </Typography>
                                                    understood
                                                    </Typography>
                                                </Box>

                                                <Box className="rightSolution orgbdr radius8">
                                                    <Box className="bdrDash solutionSmall" sx={{display:{xs: 'flex', alignItems: 'center'}}}>
                                                        <Typography variant='div' className='YellowCircle' sx={{mr:1}}>
                                                        <svg width="28" height="28" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/  svg">
                                                            <path d="M8.51625 3.00781L6.75625 4.76531L9.375 7.38281L11.1325 5.62531L8.51625 3.00781ZM31.485 3.00781L28.8663 5.62531L30.625 7.38281L33.2425 4.76656L31.485 3.00781ZM20 3.78781C19.5875 3.79281 19.17 3.81656 18.75 3.86781C18.7375 3.86781 18.725 3.86531 18.7125 3.86781C13.6425 4.44906 9.6075 8.54531 8.90625 13.5928C8.34625 17.6553 10.0212 21.3391 12.8125 23.7116C13.9543 24.686 14.7265 26.0231 15 27.4991V34.9991H17.85C18.285 35.7453 19.0788 36.2491 20 36.2491C20.9212 36.2491 21.715 35.7453 22.15 34.9991H25V29.9991H25.1175V28.5141C25.1175 26.6816 26.07 24.8341 27.6175 23.3978C29.6875 21.3253 31.25 18.3816 31.25 15.0003C31.25 8.82531 26.1675 3.73031 20 3.78781ZM20 6.28781C24.8312 6.22031 28.75 10.1753 28.75 15.0003C28.75 17.6178 27.5375 19.9228 25.8587 21.6003L25.8988 21.6403C24.2305 23.1787 23.1517 25.2513 22.8487 27.5003H17.3812C17.1062 25.3578 16.1875 23.2841 14.4513 21.7966C12.2425 19.9216 10.9263 17.0966 11.3663 13.9066C11.9125 9.96906 15.1075 6.80281 19.0225 6.36906C19.3457 6.324 19.6712 6.29729 19.9975 6.28906L20 6.28781ZM2.5 15.0003V17.5003H6.25V15.0003H2.5ZM33.75 15.0003V17.5003H37.5V15.0003H33.75ZM9.375 25.1178L6.7575 27.7341L8.51625 29.4928L11.1312 26.8753L9.375 25.1178ZM30.625 25.1178L28.8675 26.8753L31.4837 29.4928L33.2425 27.7341L30.625 25.1178ZM17.5 30.0003H22.5V32.5003H17.5V30.0003Z" fill="black"/>
                                                            </svg>
                                                        </Typography>
                                                        <Typography  sx={{margin:0}}>
                                                            solution
                                                        </Typography>
                                                    </Box>
                                                    <Box className="SolutionSummary">
                                                        <Typography variant='h3' sx={{mb:1}}>
                                                        The correct option is D
                                                        </Typography>
                                                        <Typography>
                                                        As we can see in the diagram there are 1.5 million males and 1.5 million females under the age group 15 which is largest as compared to the other age groups.
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box className="tutorBtnBox" sx={{mt:2}}>
                                                        <button className='tuterBtn' sx={{display:{xs: 'flex', alignItems: 'center', justifyContent:'center'}}}>
                                                            <Typography variant='div' sx={{display:{xs: 'flex', alignItems: 'center', justifyContent:'center'}}}>
                                                            <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="30" height="30" rx="15" transform="matrix(-1 0 0 1 30 0)" fill="white"/>
                                                            <path d="M9.17493 21.4788L10.4634 18.782C10.7407 18.2058 11.0992 17.7864 11.718 18.0421L12.9388 18.499C13.9162 18.9558 14.4031 18.499 14.8935 17.8103L17.0916 12.7677C17.3689 12.1915 17.2235 11.6494 16.6047 11.3937L14.8969 10.705C14.278 10.4458 14.3761 9.90714 14.6534 9.33095L16.1177 6.58292C16.395 6.00673 16.9631 5.86694 17.582 6.12265C18.8197 6.63748 19.8444 7.44552 20.5106 8.64224C21.3222 10.1049 20.9164 12.1403 20.7541 12.9961C20.5917 13.8519 20.0236 15.352 19.2898 16.8897C18.5559 18.4308 17.9134 19.6411 17.3351 20.3264C16.7602 21.0117 15.3805 22.8869 13.6727 23.3063C12.2726 23.6473 10.761 23.3609 9.52326 22.846C8.90101 22.6005 8.90101 22.0584 9.17493 21.4788Z" fill="#005DB8"/>
                                                            </svg>
                                                                    Ask Tutor
                                                            </Typography>
                                                            </button>
                                                    </Box>
                                            </Box>
                                        </Box>
                                        </AccordionDetails>
                                    </Accordion> */}

                                    {/* <Accordion>
                                        <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel3-content"
                                        id="panel3-header"
                                        >
                                        <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                            <Box className="cardIcon">3</Box>
                                            <Box className="cardContent"><Typography variant='h4'>What Is An Example Of A Characteristic That Shows Discontinuous Variation In Humans ?</Typography></Box>
                                        </Box>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                        <Box className="Classification">
                                            <Box className="selectOption">
                                                <Typography sx={{mb:2}}>
                                                    select one option
                                                </Typography>
                                                <Box className="questioList" >
                                                    <Box className="questionListIn green" sx={{mb:1, display: { xs: 'flex', alignItems: 'center', }}}>
                                                        <Box className="cardIcon">A</Box>
                                                        <Typography>
                                                        Biological process of producing young ones
                                                        <Typography variant='div'>
                                                        correct answer
                                                            </Typography>
                                                        </Typography>
                                                    </Box>
                                                    <Box className="questionListIn" sx={{mb:1,  display: { xs: 'flex', alignItems: 'center', }}}>
                                                        <Box className="cardIcon">B</Box>
                                                        <Typography>
                                                        Non-biological process of young ones
                                                        </Typography>
                                                    </Box>
                                                    <Box className="questionListIn pink" sx={{mb:1,  display: { xs: 'flex', alignItems: 'center', }}}>
                                                        <Box className="cardIcon">C</Box>
                                                        <Typography>
                                                        Biological process of producing mature ones
                                                            <Typography variant='div'>
                                                            wrong answer
                                                            </Typography>
                                                        </Typography>
                                                    </Box>
                                                    <Box className="questionListIn" sx={{  display: { xs: 'flex', alignItems: 'center', }}}>
                                                        <Box className="cardIcon">D</Box>
                                                        <Typography>
                                                        None of above
                                                        </Typography>
                                                    </Box>
                                                </Box>

                                                <Box className="QuestionSolution" sx={{mt:3}}>
                                                    <Box className="cardMain orgbdr lightYellow">
                                                        <Box className="cardTop" sx={{ display: {xs: 'flex', alignItems: 'center', justifyContent: 'space-between'} }}>
                                                            <Box className="cardRight" sx={{ display: {xs: 'flex', alignItems: 'center'} }}>
                                                                <Box className="cardIcon YellowCircle">
                                                                <Typography variant='div'>
                                                                <svg width="34" height="34" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/  svg">
                                                                <path d="M8.51625 3.00781L6.75625 4.76531L9.375 7.38281L11.1325 5.62531L8.51625 3.00781ZM31.485 3.00781L28.8663 5.62531L30.625 7.38281L33.2425 4.76656L31.485 3.00781ZM20 3.78781C19.5875 3.79281 19.17 3.81656 18.75 3.86781C18.7375 3.86781 18.725 3.86531 18.7125 3.86781C13.6425 4.44906 9.6075 8.54531 8.90625 13.5928C8.34625 17.6553 10.0212 21.3391 12.8125 23.7116C13.9543 24.686 14.7265 26.0231 15 27.4991V34.9991H17.85C18.285 35.7453 19.0788 36.2491 20 36.2491C20.9212 36.2491 21.715 35.7453 22.15 34.9991H25V29.9991H25.1175V28.5141C25.1175 26.6816 26.07 24.8341 27.6175 23.3978C29.6875 21.3253 31.25 18.3816 31.25 15.0003C31.25 8.82531 26.1675 3.73031 20 3.78781ZM20 6.28781C24.8312 6.22031 28.75 10.1753 28.75 15.0003C28.75 17.6178 27.5375 19.9228 25.8587 21.6003L25.8988 21.6403C24.2305 23.1787 23.1517 25.2513 22.8487 27.5003H17.3812C17.1062 25.3578 16.1875 23.2841 14.4513 21.7966C12.2425 19.9216 10.9263 17.0966 11.3663 13.9066C11.9125 9.96906 15.1075 6.80281 19.0225 6.36906C19.3457 6.324 19.6712 6.29729 19.9975 6.28906L20 6.28781ZM2.5 15.0003V17.5003H6.25V15.0003H2.5ZM33.75 15.0003V17.5003H37.5V15.0003H33.75ZM9.375 25.1178L6.7575 27.7341L8.51625 29.4928L11.1312 26.8753L9.375 25.1178ZM30.625 25.1178L28.8675 26.8753L31.4837 29.4928L33.2425 27.7341L30.625 25.1178ZM17.5 30.0003H22.5V32.5003H17.5V30.0003Z" fill="black"/>
                                                                </svg>
                                                                </Typography>
                                                                </Box>
                                                                <Box className="cardContent">
                                                                    <Typography variant='h4'>solution   </Typography>
                                                                </Box>
                                                                
                                                            </Box>
                                                            <Box className="cardLeft">
                                                                <Button variant="contained" className='darkYellow'>check solution</Button>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>

                                                <Box className="understoodBox">
                                                    <Typography sx={{mt:1, mb:2, display:{xs: 'flex', alignItems: 'center'}}}>
                                                        <Typography variant='div' sx={{mr:1}}>
                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="12" cy="12" r="11" fill="#005DB8"/>
                                                            <path d="M10.243 16.3151L6 12.0711L7.414 10.6571L10.243 13.4851L15.899 7.82812L17.314 9.24312L10.243 16.3151Z" fill="white"/>
                                                            </svg>
                                                        </Typography>
                                                    understood
                                                    </Typography>
                                                </Box>

                                                <Box className="rightSolution orgbdr radius8">
                                                    <Box className="bdrDash solutionSmall" sx={{display:{xs: 'flex', alignItems: 'center'}}}>
                                                        <Typography variant='div' className='YellowCircle' sx={{mr:1}}>
                                                        <svg width="28" height="28" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/  svg">
                                                            <path d="M8.51625 3.00781L6.75625 4.76531L9.375 7.38281L11.1325 5.62531L8.51625 3.00781ZM31.485 3.00781L28.8663 5.62531L30.625 7.38281L33.2425 4.76656L31.485 3.00781ZM20 3.78781C19.5875 3.79281 19.17 3.81656 18.75 3.86781C18.7375 3.86781 18.725 3.86531 18.7125 3.86781C13.6425 4.44906 9.6075 8.54531 8.90625 13.5928C8.34625 17.6553 10.0212 21.3391 12.8125 23.7116C13.9543 24.686 14.7265 26.0231 15 27.4991V34.9991H17.85C18.285 35.7453 19.0788 36.2491 20 36.2491C20.9212 36.2491 21.715 35.7453 22.15 34.9991H25V29.9991H25.1175V28.5141C25.1175 26.6816 26.07 24.8341 27.6175 23.3978C29.6875 21.3253 31.25 18.3816 31.25 15.0003C31.25 8.82531 26.1675 3.73031 20 3.78781ZM20 6.28781C24.8312 6.22031 28.75 10.1753 28.75 15.0003C28.75 17.6178 27.5375 19.9228 25.8587 21.6003L25.8988 21.6403C24.2305 23.1787 23.1517 25.2513 22.8487 27.5003H17.3812C17.1062 25.3578 16.1875 23.2841 14.4513 21.7966C12.2425 19.9216 10.9263 17.0966 11.3663 13.9066C11.9125 9.96906 15.1075 6.80281 19.0225 6.36906C19.3457 6.324 19.6712 6.29729 19.9975 6.28906L20 6.28781ZM2.5 15.0003V17.5003H6.25V15.0003H2.5ZM33.75 15.0003V17.5003H37.5V15.0003H33.75ZM9.375 25.1178L6.7575 27.7341L8.51625 29.4928L11.1312 26.8753L9.375 25.1178ZM30.625 25.1178L28.8675 26.8753L31.4837 29.4928L33.2425 27.7341L30.625 25.1178ZM17.5 30.0003H22.5V32.5003H17.5V30.0003Z" fill="black"/>
                                                            </svg>
                                                        </Typography>
                                                        <Typography  sx={{margin:0}}>
                                                            solution
                                                        </Typography>
                                                    </Box>
                                                    <Box className="SolutionSummary">
                                                        <Typography variant='h3' sx={{mb:1}}>
                                                        The correct option is D
                                                        </Typography>
                                                        <Typography>
                                                        As we can see in the diagram there are 1.5 million males and 1.5 million females under the age group 15 which is largest as compared to the other age groups.
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                                <Box className="tutorBtnBox" sx={{mt:2}}>
                                                        <button className='tuterBtn' sx={{display:{xs: 'flex', alignItems: 'center', justifyContent:'center'}}}>
                                                            <Typography variant='div' sx={{display:{xs: 'flex', alignItems: 'center', justifyContent:'center'}}}>
                                                            <svg width="20" height="20" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <rect width="30" height="30" rx="15" transform="matrix(-1 0 0 1 30 0)" fill="white"/>
                                                            <path d="M9.17493 21.4788L10.4634 18.782C10.7407 18.2058 11.0992 17.7864 11.718 18.0421L12.9388 18.499C13.9162 18.9558 14.4031 18.499 14.8935 17.8103L17.0916 12.7677C17.3689 12.1915 17.2235 11.6494 16.6047 11.3937L14.8969 10.705C14.278 10.4458 14.3761 9.90714 14.6534 9.33095L16.1177 6.58292C16.395 6.00673 16.9631 5.86694 17.582 6.12265C18.8197 6.63748 19.8444 7.44552 20.5106 8.64224C21.3222 10.1049 20.9164 12.1403 20.7541 12.9961C20.5917 13.8519 20.0236 15.352 19.2898 16.8897C18.5559 18.4308 17.9134 19.6411 17.3351 20.3264C16.7602 21.0117 15.3805 22.8869 13.6727 23.3063C12.2726 23.6473 10.761 23.3609 9.52326 22.846C8.90101 22.6005 8.90101 22.0584 9.17493 21.4788Z" fill="#005DB8"/>
                                                            </svg>
                                                                    Ask Tutor
                                                            </Typography>
                                                            </button>
                                                    </Box>
                                            </Box>
                                        </Box>
                                        </AccordionDetails>
                                    </Accordion> */}
                                </Box>

                                { attemptedAnswer?.length > 0 && <Box className="checkAnswer" sx={{mt:4, display: { xs: 'flex', alignItems: 'center', justifyContent: 'center' } }} >
                                    <button className='checkAnswerBtn' onClick={handleAnswerCheck}>{translation?.CheckAnswer} ({attemptedAnswer?.length})</button>
                                </Box> }
                            </Box>
                           

                        </Box>
                    </Box>
                </Box>
            </Box>

        <MessageModal isOpen={isMessage} btnTxt={translation?.OkButton} />
        <QuickCallModal isOpen={openModal} isApiCalling={isApiCalling} translation={translation} handleClose={closeModal} />
        <FeedbackModal isOpen={isFeedback} feedback={feedback} translation={translation} onChangeFeedback={onChangeFeedback} onSubmitFeedback={onSubmitFeedback} />
        <FooterSection />
    </div>
  )
}

export default MockTestQuestions