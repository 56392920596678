import React, { useState, useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import Cookies from 'js-cookie';

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import useTranslations from "./custom-hooks/useTranslations.js";
import { getStudentDashBoard } from "./actions/studentDashboardAction.js";


import { useDispatch, useSelector } from "react-redux";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import { sessionRejoined } from "./actions/sessionRejoinedAction.js";
import generateAgoraToken from "./services/AgoraTokenService.js";
import { encryptData } from "./services/encryptDecrypt.js";
import { axiosGet } from "./services/api.js";
import { API_BASE_URL } from "./config/apiConfig.js";

import ProtectedRoute from "./components/auth/ProtectedRoute.jsx";
import LaunchClassRoom from "./components/agora/LaunchClassRoom";
import LaunchQuickCallRoom from "./components/agora/LaunchQuickCallRoom";
import LaunchHireClassRoom from "./components/agora/LaunchHireClassRoom.jsx";
import LaunchFreeCall from "./components/agora/LaunchFreeCall.jsx";

import Login from "./components/login/Login.jsx";
import SelectStudy from "./components/select-study/SelectStudy.jsx";
import Registration from "./components/registration/Registration.jsx";

import QuickCall from "./components/quick-call/QuickCall.jsx";
import HireTutor from "./components/hire-tutor/HireTutor.jsx";
import FreeGroupClasses from "./components/free-group-classes/FreeGroupClasses.jsx";
import PrivateClass from "./components/private-class/PrivateClass.jsx";
import PrivateClassInvoice from "./components/private-class-invoice/PrivateClassInvoice.jsx";
import Test from "./components/test/Test.jsx";
import Wallet from "./components/wallet/Wallet.jsx";
import UserProfile from "./components/user-profile/UserProfile.jsx";
import ReferEarn from "./components/refer-earn/ReferEarn.jsx";

import SessionList from "./components/session-list/SessionList.jsx";
import SessionDetail from "./components/session-detail/SessionDetail.jsx";
import TutorProfile from "./components/tutor-profile/TutorProfile.jsx";
import Checkout from "./components/checkout/Checkout.jsx";
import Overview from "./components/overview/Overview.jsx";

import Notes from "./components/study-material/Notes.jsx";
import Subject from "./components/subject/Subject.jsx";
import Chapter from "./components/chapter/Chapter.jsx";
import TestDetail from "./components/test-detail/TestDetail.jsx";
import StudyMaterial from "./components/study-material/StudyMaterial.jsx";


import PracticeTest from "./components/practice-test/PracticeTest.jsx";
import PracticeTestQuestion from "./components/practice-test-question/PracticeTestQuestion.jsx";

import MockTest from "./components/mock-test/MockTest.jsx";
import MockTestDetail from "./components/mock-test-detail/MockTestDetail.jsx";
import MockTestQuestionsDetail from "./components/mock-test-questions-detail/MockTestQuestionsDetail.jsx";
import MockTestQuestions from "./components/mock-test-questions/MockTestQuestions.jsx";
import MockTestResult from "./components/mock-test-result/MockTestResult.jsx";



import Solution from "./components/solution/Solution.jsx";
import PreviousSolution from "./components/previous-solution/PreviousSolution.jsx";

import MultiDevice from "./components/multi-device/MultiDevice.js";
import NoPage from "./components/no-page/NoPage.jsx";
import useSocket from "./custom-hooks/useSocket.js";

import "./App.css";
import Payment from "./components/payment/Payment.jsx";
import InvoicePage from "./components/invoice/InvoicePage.jsx";
import { getClasses } from "./actions/classesAction.js";
import { studentSessionDetails } from "./actions/studentSessionDetailsAction.js";
import { getTransactions } from "./actions/getTransactionsAction.js";
import Courses from "./components/courses/Courses.jsx";
import CoursesDetail from "./components/courses/CoursesDetail.jsx";
import LaunchCourseRoom from "./components/agora/LaunchCourseRoom.jsx";
import { getCourses } from "./actions/coursesAction.js";

let isApiCalling = false;

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { socket, isConnected } = useSocket();
  const translations = useTranslations();
  const [isOffline, setIsOffline] = useState(false);
  const translation = translations[21]?.CommonFrontend;

  const studentDashboard = useSelector(({ studentDashboardReducer }) => studentDashboardReducer.dashboard);
  const classes = useSelector(({ classReducer }) => classReducer.classes || {});
  const sessionDetails = useSelector(({ studentSessionDetailsReducer }) => studentSessionDetailsReducer.sessionDetails || {});
  const transactionsData = useSelector(({ getTransactionsReducer }) => getTransactionsReducer?.transactions|| {}) ;
  const courses = useSelector(({ coursesReducer }) => coursesReducer.courses || {});
  
  

  const rejoinSession = () => {
    const user = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : null;
    const studentProfile = Cookies.get("ProfileInfo") ? JSON.parse(Cookies.get("ProfileInfo")): null;
    const userUid = user?.uid;
    const { ID, TutorID, TutorName, TutorProfilePictureUrl } =
    studentDashboard?.PrivateTutorInfo[0];
    const channelName = `channel${ID}`;
    sessionStorage.setItem("SessionID", ID);
    const tutorResponseData = {};
    tutorResponseData["TutorID"] = TutorID;
    tutorResponseData["TutorName"] = TutorName;
    tutorResponseData["TutorImage"] = TutorProfilePictureUrl;
    tutorResponseData["StudentID"] = studentProfile.ID;
    tutorResponseData["StudentName"] = studentProfile?.UserName;
    tutorResponseData["StudentImage"] = studentProfile?.ProfilePictureUrl;
    sessionStorage.setItem('pathName', 'quick-call');
    sessionStorage.setItem("TutorResponse", JSON.stringify(tutorResponseData));
    sessionRejoined({ TutorID: TutorID, SessionID: ID });
    generateAgoraToken(userUid, 2, channelName).then((res) => {
      localStorage.setItem("rtmToken", JSON.stringify(res.RtmToken));
      localStorage.setItem("rtcToken", JSON.stringify(res.RtcToken));
      navigate(`/hire-class-room/${encryptData(ID)}`);
    });
  };

  const refreshToken = async () => {
        
    const loginToken = JSON.parse(Cookies.get("loginToken") || "{}");
    const currentTime = Date.now();
    const bufferTime = 25 * 60 * 1000; // 25 minutes buffer    
  
    if (loginToken?.expireTime && currentTime >= loginToken.expireTime - bufferTime) {
      console.log("Refreshing token...");
      try {
        const response = await axiosGet(`${API_BASE_URL}/CustomToken`);
        console.log("Token refreshed at: ", new Date().toLocaleTimeString(), response);
  
        // Update expireTime based on expiresIn from API
        const payload = {
          idToken: response?.data?.Token,
          expiresIn: loginToken?.expiresIn,
          localId: loginToken?.localId,
          expireTime: currentTime + loginToken.expiresIn * 1000,
        };
        Cookies.set("loginToken", JSON.stringify(payload) , { expires: 25 });
      } catch (error) {
        console.error("Error refreshing token:", error);
      }
    }
  };

  useEffect(() => {
    const isSessionEnd = sessionStorage.getItem('StudentSessionEnd');
    if (studentDashboard?.PrivateTutorInfo?.length > 0 && !isSessionEnd) {
      rejoinSession();
    }
  }, [studentDashboard]);

  useEffect(() => {
    const refreshInterval = 25 * 60 * 1000; // Refresh token every 25 minutes
    const refreshTimer = setInterval(refreshToken, refreshInterval);

    if (!studentDashboard && Cookies.get("ProfileInfo") ? Cookies.get("ProfileInfo"): null !== null && isApiCalling === false) {
      isApiCalling = true;
      dispatch(getStudentDashBoard())
    }

    if (Cookies.get("ProfileInfo")) {

      if (classes && Object.keys(classes).length === 0 ) {
        dispatch(getClasses());
      }
  
      if (sessionDetails && Object.keys(sessionDetails).length === 0 ) {
        dispatch(studentSessionDetails({PageSize: 10, Page: 1, CallType: 0, FilterID: 0}))
      }
      
      if (transactionsData && Object.keys(transactionsData).length === 0 ) {
        dispatch(getTransactions());
      }

      if (courses && Object.keys(courses).length === 0 ) {
        dispatch(getCourses());
      }
      
    }
    

    

    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") refreshToken();
    };

    const updateOnlineStatus = () => {
      setIsOffline(!navigator.onLine);
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);
    window.addEventListener("online", updateOnlineStatus);
    window.addEventListener("offline", updateOnlineStatus);

    return () => {
      clearInterval(refreshTimer);
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      window.removeEventListener("online", updateOnlineStatus);
      window.removeEventListener("offline", updateOnlineStatus);
    };
  }, []);

  return (
    <>
      {/* network disconnect  */}
      <Dialog
        open={isOffline}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div className="offline-container">
            <h2 className="title-1 text-center mt-5">
              {translation?.NetworkDisconnected}
            </h2>
            <div className="image-container"></div>
            <p className="title-info text-center">{translation?.NetworkLost}</p>
          </div>
        </DialogContent>
      </Dialog>
      <Routes>
        <Route
          path="/login"
          element={
            <UserAuthContextProvider>
              <Login />
            </UserAuthContextProvider>
          }
        />

        <Route
          path="/"
          element={
            <ProtectedRoute>
              <SelectStudy />
            </ProtectedRoute>
          }
        />

        <Route
          path="/registration"
          element={
            <ProtectedRoute>
              <Registration />
            </ProtectedRoute>
          }
        />

        <Route
          path="/quick-call"
          element={
            <ProtectedRoute>
              <QuickCall />
            </ProtectedRoute>
          }
        />

        <Route
          path="/hire-tutor"
          element={
            <ProtectedRoute>
              <HireTutor />
            </ProtectedRoute>
          }
        />

        <Route
          path="/free-group-classes"
          element={
            <ProtectedRoute>
              <FreeGroupClasses />
            </ProtectedRoute>
          }
        />

        <Route
          path="/private-class"
          element={
            <ProtectedRoute>
              <PrivateClass />
            </ProtectedRoute>
          }
        />

        <Route
          path="/courses"
          element={
            <ProtectedRoute>
              <Courses />
            </ProtectedRoute>
          }
        />

        <Route
          path="/courses-detail/:classId"
          element={
            <ProtectedRoute>
              <CoursesDetail/>
            </ProtectedRoute>
          }
        />

        {/* <Route
          path="/private-class-invoice/:encryptedClassId"
          element={
            <PrivateClassInvoice />
          }
        />  */}
        
        <Route
          path="/private-class-invoice/:classId"
          element={
            <InvoicePage />
          }
        />

        <Route
          path="/test"
          element={
            <ProtectedRoute>
              <Test />
            </ProtectedRoute>
          }
        />

        <Route
          path="/wallet"
          element={
            <ProtectedRoute>
              <Wallet />
            </ProtectedRoute>
          }
        />

        <Route
          path="/refer-earn"
          element={
            <ProtectedRoute>
              <ReferEarn />
            </ProtectedRoute>
          }
        />

        <Route
          path="/user-profile"
          element={
            <ProtectedRoute>
              <UserProfile />
            </ProtectedRoute>
          }
        />

        <Route
          path="/session-list"
          element={
            <ProtectedRoute>
              <SessionList />
            </ProtectedRoute>
          }
        />

        <Route
          path="/session-detail/:sessionId/:tutorId"
          element={
            <ProtectedRoute>
              <SessionDetail />
            </ProtectedRoute>
          }
        />

        <Route
          path="/tutor-profile/:tutorId"
          element={
            <ProtectedRoute>
              <TutorProfile />
            </ProtectedRoute>
          }
        />

        <Route
          path="/Checkout"
          element={
            <ProtectedRoute>
              <Checkout />
            </ProtectedRoute>
          }
        />

        <Route
          path="/overview/:classId"
          element={
            <ProtectedRoute>
              <Overview />
            </ProtectedRoute>
          }
        />

        <Route
          path="/overview/:classId/:tab"
          element={
            <ProtectedRoute>
              <Overview />
            </ProtectedRoute>
          }
        />

        <Route
          path="/notes/:noteId"
          element={
            <ProtectedRoute>
              <Notes />
            </ProtectedRoute>
          }
        />

        <Route
          path="/practice-test"
          element={
            <ProtectedRoute>
              <Subject />
            </ProtectedRoute>
          }
        />

        <Route
          path="/mock-test"
          element={
            <ProtectedRoute>
              <Subject />
            </ProtectedRoute>
          }
        />

        <Route
          path="/practice-test/:subjectId"
          element={
            <ProtectedRoute>
              <Chapter />
            </ProtectedRoute>
          }
        />

        <Route
          path="/chapter/:subjectId"
          element={
            <ProtectedRoute>
              <Chapter />
            </ProtectedRoute>
          }
        />

        <Route
          path="/test-detail/:subjectId/:chapterId"
          element={
            <ProtectedRoute>
              <TestDetail />
            </ProtectedRoute>
          }
        />

        <Route
          path="/study-material/:subjectId/:chapterId/:materialId"
          element={
            <ProtectedRoute>
              <StudyMaterial />
            </ProtectedRoute>
          }
        />

        <Route
          path="/practice-test/:subjectId/:chapterId"
          element={
            <ProtectedRoute>
              <PracticeTest />
            </ProtectedRoute>
          }
        />

        <Route
          path="/practice-test-question/:subjectId/:chapterId/:questionId"
          element={
            <ProtectedRoute>
              <PracticeTestQuestion />
            </ProtectedRoute>
          }
        />

        <Route
          path="/mock-test/:subjectId"
          element={
            <ProtectedRoute>
              <MockTest />
            </ProtectedRoute>
          }
        />

        <Route
          path="/mock-test-detail/:subjectId/:mockTestId"
          element={
            <ProtectedRoute>
              <MockTestDetail />
            </ProtectedRoute>
          }
        />

        <Route
          path="/mock-test-questions-detail/:subjectId/:mockTestId"
          element={
            <ProtectedRoute>
              <MockTestQuestionsDetail />
            </ProtectedRoute>
          }
        />

        <Route
          path="/mock-test-by-mark/:subjectId/:mockTestId/:questionMark"
          element={
            <ProtectedRoute>
              <MockTestQuestions />
            </ProtectedRoute>
          }
        />

        <Route
          path="/mock-test-by-chapter/:subjectId/:mockTestId/:questionChapterId"
          element={
            <ProtectedRoute>
              <MockTestQuestions />
            </ProtectedRoute>
          }
        />

        <Route
          path="/mock-test-result/:subjectId/:mockTestId"
          element={
            <ProtectedRoute>
              <MockTestResult />
            </ProtectedRoute>
          }
        />

        <Route
          path="/classroom/:encryptClassId/:encryptSessionId"
          element={
            <ProtectedRoute>
              <LaunchClassRoom />
            </ProtectedRoute>
          }
        />

        <Route
          path="/course/:encryptClassId/:encryptSessionId"
          element={
            <ProtectedRoute>
              <LaunchCourseRoom />
            </ProtectedRoute>
          }
        />

        <Route
          path="/quick-calls-room/:sessionID"
          element={
            <ProtectedRoute>
              <LaunchQuickCallRoom />
            </ProtectedRoute>
          }
        />

        <Route
          path="/hire-class-room/:sessionID"
          element={
            <ProtectedRoute>
              <LaunchHireClassRoom />
            </ProtectedRoute>
          }
        />

        <Route
          path="/free-group-call/:sessionID"
          element={
            <ProtectedRoute>
              <LaunchFreeCall />
            </ProtectedRoute>
          }
        />
        
        <Route
          path="/PaymentStatus"
          element={
            <Payment />
          }
        />

        <Route path="/solution" element={<Solution />} />
        <Route path="/previous-solution" element={<PreviousSolution />} />

        <Route path="/multi-device" element={<ProtectedRoute><MultiDevice /></ProtectedRoute>} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </>
  );
};

export default App;
