import React, { useEffect } from "react";
import {agoraFreeGroupCallAttendence } from "./AgoraCommon";
import useTranslations from "../../custom-hooks/useTranslations";
import Cookies from 'js-cookie';
import { launchAgoraClassroom } from "../../services/Utils/agoraUtils";


const LaunchFreeCallRoom = () => {
  const translations = useTranslations();
	const translation = translations[33]?.ApplicationConfiguration;
  const agoraCallDuration = translation?.AgoraCallDuration;
  const sessionId = sessionStorage.getItem('SessionID');
  const userInfo = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : null;
  const profileInfo = Cookies.get("ProfileInfo") ? JSON.parse(Cookies.get("ProfileInfo")): null;
  const rtmToken = JSON.parse(localStorage.getItem("rtmToken"));
  const roomName = `studentcourses${sessionId}`;
  const roomUuid = `studentcoursesuuid${sessionId}`;
  const channel = `channel${sessionId}`;
  const roomType = 4;
  const roleType = 2;
  const sessionType = 2;
  const userUid = userInfo?.uid;
  const userName = profileInfo?.UserName;
  const pathName = "/free-group-classes";
  const flag =  sessionStorage.getItem('flag');
 

  useEffect(() => {
    localStorage.setItem("SessionType", "SC");
    localStorage.setItem("CallType", 4);
    const params = { userUid, userName, roomUuid, sessionId, roomName, channel, rtmToken, roomType, sessionType, pathName, agoraFreeGroupCallAttendence ,agoraCallDuration};

    launchAgoraClassroom(params);

  }, []);
  return;
};
export default LaunchFreeCallRoom;
