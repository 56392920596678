import React, { useState, useEffect } from 'react'
import { Box, Typography } from '@mui/material'
import Cookies from 'js-cookie';

import Header from "../header/Header";

import FooterSection from "../footer/FooterSection";
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';


import "../../App.css";
import "../subject/Subject.css";
import "./Checkout.css"
import "../session-list/SessionList.css";
import "../responsive/Responsive.css";

import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import useSocket from '../../custom-hooks/useSocket';
import useTranslations from '../../custom-hooks/useTranslations';
import { openMessageModal } from '../../actions/modalAction';
import generateAgoraToken from '../../services/AgoraTokenService';
import { encryptData } from '../../services/encryptDecrypt';

import MessageModal from '../common-components/MessageModal';


const Checkout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isConnected, socket } = useSocket();
  const translations = useTranslations();
  const user = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : null
  const profileInfo = Cookies.get("ProfileInfo") ? JSON.parse(Cookies.get("ProfileInfo")): null;
  const tutorResponse = JSON.parse(sessionStorage.getItem('TutorResponse'));
  const studentRequest = JSON.parse(sessionStorage.getItem('StudentRequest'));
  const [isDisabled, setIsDisabled] = useState(false);
  const [istutorJoin, setistutorJoin] = useState(false);

  const isMessage = useSelector(({ modalReducer }) => modalReducer.isMessage);

  const translation = {
    ...translations[8]?.PrivateClassDashboard,
    ...translations[21]?.CommonFrontend,
    ...translations[26]?.QuickCall,
    ...translations[29]?.FindTutor,
    ...translations[31]?.Wallet
  }

  const onPayClickHandler = async () => {
    setIsDisabled(true);
    studentRequest.isPaymentDone = 2;
    studentRequest.SessionID = parseInt(studentRequest.SessionID)
    socket.emit('PTModule', JSON.stringify(studentRequest));  
    sessionStorage.setItem('SessionID', tutorResponse?.SessionID);
  }

  const onCancelClickHandler = async () => {
    setIsDisabled(true);
    studentRequest.SessionID = parseInt(studentRequest.SessionID)
    studentRequest.IsDecline = true;
    studentRequest.IsAccept = true;
    studentRequest.isPaymentDone = 3;
    
    socket.emit('PTModule', JSON.stringify(studentRequest));
    sessionStorage.removeItem("StudentRequest");
    sessionStorage.removeItem("TutorResponse")
    navigate('/hire-tutor');
  }

  const onConnectHireTutor = () => {
    const sessionID = sessionStorage.getItem('SessionID');
    const userUid = user?.uid;
    const channelName = `channel${sessionID}`;
    generateAgoraToken(userUid, 2, channelName).then((res) => {
      localStorage.setItem("rtmToken", JSON.stringify(res.RtmToken));
      localStorage.setItem("rtcToken", JSON.stringify(res.RtcToken));
      navigate(`/hire-class-room/${encryptData(sessionID)}`);
    });
  }


  useEffect(() => {
    if (isConnected) {
      socket.on('responseEvent', (response) => {
        const responseData = JSON.parse(response);
        if (responseData.StatusCode === 200 && responseData.Data[0].isPaymentDone === 2) {
          setistutorJoin(true);
        } else if (responseData.StatusCode === 200 && responseData.Data[0].isPaymentDone === 4) {
          onConnectHireTutor();
        }
         else if (responseData.StatusCode === 400) {
          // toast(responseData.Message)
          dispatch(openMessageModal(translation?.ExceededFreeCallLimit));
        } else if (responseData.StatusCode === 402) {
          // toast(translation?.LowAmountMessage)
          dispatch(openMessageModal(translation?.LowAmountMessage));
        } 
        
      });
    }
  }, [isConnected]);

  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Header />
      <Box className="quikCallBanner">
        <Box className='OuterContainer' sx={{ border: 'none', borderRadius: '0px' }}>
          <Typography variant='h1'>
            {translation?.Hi} {profileInfo?.UserName}, <Typography variant='div'> {translation?.WelcomeYasa}</Typography>
          </Typography>
        </Box>
      </Box>

      <Box className='OuterContainer containerShape' sx={{ background: '#EAF4FF', padding: '30px' }}>
        <Box className='SectionIn'>
          <Box className="tutor_cprofile">
            <Box className="tutor_cprofile_inner">
              <Box className="tc_wrap">
                <img src={studentRequest?.TutorImage}></img>
              </Box>
              <Box className="content-in">
                <Typography variant='h3' sx={{ color: '#005DB8', fontSize: '18px', display: { xs: 'flex', alignItems: 'center', justifyContent: 'center' } }}>
                  {studentRequest?.TutorName}
                  <Typography variant='div'>
                    <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g clipPath="url(#clip0_4705_2224)">
                        <path d="M11.4316 6.23036V6.69036C11.431 7.76857 11.0819 8.8177 10.4363 9.68128C9.79072 10.5449 8.88328 11.1766 7.84932 11.4823C6.81536 11.788 5.71027 11.7513 4.69888 11.3777C3.68748 11.004 2.82397 10.3134 2.23712 9.4089C1.65027 8.50438 1.37154 7.43439 1.44248 6.35852C1.51342 5.28264 1.93024 4.25853 2.63078 3.4389C3.33132 2.61927 4.27803 2.04805 5.32974 1.81043C6.38144 1.57282 7.48177 1.68153 8.46664 2.12036" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M11.4316 2.69141L6.43164 7.69641L4.93164 6.19641" stroke="#005DB8" strokeLinecap="round" strokeLinejoin="round" />
                      </g>
                      <defs>
                        <clipPath id="clip0_4705_2224">
                          <rect width="12" height="12" fill="white" transform="translate(0.431641 0.691406)" />
                        </clipPath>
                      </defs>
                    </svg>

                  </Typography>
                </Typography>
                <Box className="RatingBox">
                  <Stack spacing={2}>
                    <Rating sx={{direction: 'ltr'}} name="half-rating-read" value={parseFloat(studentRequest?.TutorRating)} precision={0.5} readOnly />
                  </Stack>
                </Box>
                <Typography variant='h4' sx={{ color: '#005DB8', fontSize: '14px', fontWeight: 'bold' }}>
                  <b className="moneyFont">{studentRequest?.Price} {studentRequest?.CurrencyType} </b> / {translation?.HireTutorHour}
                </Typography>
                <Box className="availableBtn" sx={{ gap: '10px', display: { xs: 'flex' }, flexDirection: 'column' }}>
                  { istutorJoin === true ?
                       <Typography variant='h6' sx={{ fontSize: '15px', fontWeight: 'bold' }}>
                          {translation?.WhileConnecting}...
                       </Typography>
                    : 
                    <>
                      <button className='darkBlue' onClick={onPayClickHandler} disabled={isDisabled}>{translation?.PayNow}</button>
                      <button className='bdrbtn' onClick={onCancelClickHandler} disabled={isDisabled}>{translation?.QuickCancel}</button>
                    </>
                  }
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <MessageModal isOpen={isMessage} btnTxt={translation?.OkButton} />
      <FooterSection />
    </div>
  )
}

export default Checkout