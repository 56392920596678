import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import useMockTest from "../../custom-hooks/useMockTest";
import useMockTestQuestions from "../../custom-hooks/useMockTestQuestions";

import { checkAnswer } from "../../actions/checkAnswerAction";

const MockTestTimer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { subjectId, mockTestId } = useParams();
  const testId = mockTestId ? atob(mockTestId) : sessionStorage.getItem("MockTestID");
  const savedTimer = sessionStorage.getItem("MockTestTimer");
  const { getMockTest, updateScore } = useMockTest(atob(subjectId));
  const mockTest = getMockTest(testId);
  const { mockTestQuestions, updateAttemptedQuestions } = useMockTestQuestions(testId);

  // Initialize time from sessionStorage or fallback to mockTest duration
  const [time, setTime] = useState(
    savedTimer ? JSON.parse(savedTimer) : mockTest?.NumberOfMinute * 60
  );
  const intervalRef = useRef();

  const handleTestCompleted = () => {
    clearInterval(intervalRef.current);
    const updateAnswer = JSON.parse(sessionStorage.getItem("AnsweredQuestion")) || [];
    updateAttemptedQuestions(updateAnswer);
    const score = mockTestQuestions?.reduce((acc, current) => {
      if (current.AnswerID === current.LastSelectedAnswerID) {
        acc = acc + current.Mark;
      }
      return acc;
    }, 0);
    updateScore(testId, score);
    const obj = {
      MockTestID: testId,
      Scored: score,
      IsCompleted: true,
      CompleteTime: mockTest?.NumberOfMinute * 60 - time,
      DataList: updateAnswer,
      IsMockTest: true,
    };
    dispatch(checkAnswer(obj));
    sessionStorage.removeItem("MockTestStart");
    sessionStorage.removeItem("MockTestTimer");
    sessionStorage.removeItem("AnsweredQuestion");
    sessionStorage.removeItem("MockTestQuestions");
    navigate(`/mock-test-result/${subjectId}/${btoa(testId)}`);
  };

  useEffect(() => {
    intervalRef.current = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(intervalRef.current);
          handleTestCompleted();
          return 0;
        }
        const updatedTime = prevTime - 1;
        sessionStorage.setItem("MockTestTimer", JSON.stringify(updatedTime));
        return updatedTime;
      });
    }, 1000);

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  const hours = Math.floor(time / 3600);
  const minutes = Math.floor((time % 3600) / 60);
  const seconds = time % 60;

  return (
    time > 0 && (
      <div className="mock-test-timer">
        <span className="stop-timer" onClick={handleTestCompleted}></span>
        <span style={{ fontWeight: 600 }}>
          {String(hours).padStart(2, "0")}:{String(minutes).padStart(2, "0")}:
          {String(seconds).padStart(2, "0")}
        </span>
      </div>
    )
  );
};

export default React.memo(MockTestTimer);
