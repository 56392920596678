import React, { useEffect, useState } from 'react'
import { Box, Grid, Typography, CircularProgress } from '@mui/material'
import Cookies from 'js-cookie';
import Button from '@mui/material/Button';
import Header from "../header/Header";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import HeroSection from "../hero-section/HeroSection";
import FooterSection from "../footer/FooterSection";
import SubTopHead from "../sub-top-head/SubTopHead";
import VideoThumbnail from 'react-video-thumbnail';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import Pagination from '@mui/material/Pagination';

import "../../App.css";
import "../subject/Subject.css";
import "../session-list/SessionList.css";
import "../responsive/Responsive.css";

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import useTranslations from '../../custom-hooks/useTranslations';
import useStudentDashboard from '../../custom-hooks/useStudentDashboard';
import momentDate from '../../services/momentDate';
import timeAgoDate from '../../services/timeAgoDate';
import Advertisement from '../common-components/Advertisement';
import FeedbackModal from '../common-components/FeedbackModal';
import { dateFns } from "../../services/dateFns";

import { API_BASE_URL } from '../../config/apiConfig';
import { axiosPost } from '../../services/api';
import { submitFeedBack } from '../../actions/submitFeedBackAction';
import { addToFavtTutor } from '../../actions/addToFavtTutorAction';
import { getStudentDashBoard } from '../../actions/studentDashboardAction';
import { studentSessionDetails, studentSessionDetailsSuccess } from '../../actions/studentSessionDetailsAction';

const sessionTypeList = [
    {Type: 'QC', Value: 'QuickCall'},
    {Type: 'PT', Value: 'HireTutor'},
    // {Type: 'PC', Value: 'Private Class'}
]

const sessionDayList = [
    {ID: 1, Value: 'Today'},
    {ID: 8, Value: 'Yesterday'},
    {ID: 2, Value: 'ThisWeek'},
    {ID: 3, Value: 'LastWeek'},
    {ID: 4, Value: 'ThisMonth'},
    {ID: 5, Value: 'LastMonth'},
    {ID: 6, Value: 'ThisYear'},
    {ID: 7, Value: 'LastYear'}
]

const SessionList = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();  
    const translations = useTranslations();
    const { studentDashboard, markAsFavTutor, addSessionRating } = useStudentDashboard();
    const [sessionList, setSessionList] = useState(studentDashboard?.RecentCalls || []);    
    const [sessionType, setSessionType] = useState("0")
    const [sessionDay, setSessionDay] = useState("0")
    const [session, setSession] = useState({});
    const [page, setPage] = useState(1);
    const [feedbackModal, setFeedbackModal] = useState(false);
    const [isApiCalling, setIsApiCalling] = useState(true);
    const [sessionFeedBack, setSessionFeedBack] = useState({
        TutorRating: '',
        FeedbackMessage: []
    });

    const profileInfo = Cookies.get("ProfileInfo") ? JSON.parse(Cookies.get("ProfileInfo")): null;
    const sessionDetails = useSelector(({ studentSessionDetailsReducer }) => studentSessionDetailsReducer.sessionDetails || {});

    const translation = {
        ...translations[7]?.PersonalInfo,
        ...translations[21]?.CommonFrontend,
        ...translations[26]?.QuickCall,
    }

    const handleBack = () => {
        navigate(-1);
    }

    const handleFavouriteTutor = (session) => {
        const updatedSessionList = sessionList.map((item) =>
            item.TutorID === session.TutorID 
              ? { ...item, FavouriteTutor: item.FavouriteTutor === "true" ? "false" : "true" }
              : item
        );
        setSessionList(updatedSessionList);
        markAsFavTutor(session?.TutorID );
        dispatch(addToFavtTutor({ TutorID: session?.TutorID }));
    }

    const handleSessionRating = (session) => {
        setFeedbackModal(true)
        setSession(session);

    }

    const onChangeFeedback = (name, value) => {
        setSessionFeedBack((prev) => {
          if (name === 'FeedbackMessage') {
            const updatedFeedBack = prev?.FeedbackMessage?.includes(value)
              ? prev.FeedbackMessage.filter((item) => item !== value)
              : [...prev.FeedbackMessage, value];
      
            return {
              ...prev,
              FeedbackMessage: updatedFeedBack,
            };
          }
      
          return {
            ...prev,
            [name]: value,
          };
        });
    };

    const onSubmitFeedback = async (e) => {
        try {
            sessionStorage.removeItem('StudentSessionEnd');
            const questionId = sessionStorage.getItem('QuestionID');
            setFeedbackModal(false);
    
            if (questionId || session.Type === 'QC') {
                const sessionId = questionId || session?.ID;
                const data = {
                    SessionID: sessionId,
                    TutorRating: sessionFeedBack.TutorRating,
                    SessionFeedbackMsg: sessionFeedBack.FeedbackMessage
                }
                addSessionRating(sessionId, sessionFeedBack.TutorRating);
                dispatch(submitFeedBack(data))
            } else {
                const sessionId = sessionStorage.getItem("SessionID") || session?.ID;
                const data = {
                    SessionID: sessionId,
                    Ratings: sessionFeedBack.TutorRating,
                    FeedbackID: sessionFeedBack.FeedbackMessage
                };
                addSessionRating(sessionId, sessionFeedBack.TutorRating);
                await axiosPost(`${API_BASE_URL}/SavePrivateTutorFeedback`, data);
            }
            setSessionFeedBack({
                TutorRating: '',
                FeedbackMessage: []
            });
            dispatch(getStudentDashBoard());
            sessionStorage.removeItem("StudentRequest");
            sessionStorage.removeItem("TutorResponse");
            sessionStorage.removeItem("QuestionID");
            sessionStorage.removeItem("SessionID");
            localStorage.removeItem("CallDuration");
            localStorage.removeItem("CallType");
        } catch (error) {
			console.error('An error occurred while saving feedback:', error);
		}
	}

    const filterSession = (type, day) => {
        if (type !== '0' && day !== '0') {
            const data = sessionDetails?.paginatedData?.filter((session) => session?.Type === type) || [];
            return dateFns(data, day) || [];
        } else if (type !== '0') {
            return sessionDetails?.paginatedData?.filter((session) => session?.Type === type) || [];
        } else if (day !== '0') {
            return dateFns(sessionDetails?.paginatedData, day) || [];
        } else {
            return sessionDetails?.paginatedData
        }
    }

    const formatCallDuration = (duration) => {
        if (!duration) return "00:00";
    
        // Split duration into whole minutes and fractional seconds
        const [minutes, fraction] = duration.toString().split(".");
        
        // Convert fractional part to seconds (if present)
        const seconds = Math.round((parseFloat(`0.${fraction || 0}`) * 60) || 0);
    
        // Pad minutes and seconds with leading zeros
        const formattedMinutes = String(minutes).padStart(2, "0");
        const formattedSeconds = String(seconds).padStart(2, "0");
    
        return `${formattedMinutes}:${formattedSeconds}`;
    };
    

    const handleChange = (e, p) => {
        // dispatch(studentSessionDetails({PageSize: 10, Page: p}))
        setPage(p);
        setIsApiCalling(true);
        dispatch(studentSessionDetails({
            PageSize: 10, 
            Page: p, 
            CallType: sessionType !== '0' ? sessionType : '', 
            FilterID: Number(sessionDay)
        }))
    }

    const viewTutorProfile = (tutorId) => {
		navigate(`/tutor-profile/${btoa(tutorId)}`);
	}

    const handleSessionTypeChange = (event) => {
        const { value } = event.target;
        // const sessions = filterSession(value, sessionDay)
        // setSessionList(sessions);
        setPage(1);
        setSessionType(value);
        setIsApiCalling(true);
        dispatch(studentSessionDetails({
            PageSize: 10, 
            Page: 1, 
            CallType: value !== '0' ? value : '', 
            FilterID: Number(sessionDay)
        }))
    };

    const handleSessionDayChange = (event) => {
        const { value } = event.target;
        // const sessions = filterSession(sessionType, value);
        // setSessionList(sessions);
        setPage(1)
        setSessionDay(value);
        setIsApiCalling(true);
        dispatch(studentSessionDetails({
            PageSize: 10, 
            Page: 1, 
            CallType: sessionType !== '0' ? sessionType : '', 
            FilterID: Number(value)
        }))
    };

    const onSessionVideoClick = (session) => {
        navigate(`/session-detail/${btoa('RS' + session.ID)}/${btoa(session.TutorID)}`)
    }

    const isHTML = (str) => /<\/?[a-z][\s\S]*>/i.test(str);

    useEffect(() => {
        if (Object.keys(sessionDetails).length > 0) {
            // const sessions = filterSession(sessionType, sessionDay)
            // setSessionList(sessions);
            setIsApiCalling(false);
            setSessionList(sessionDetails?.paginatedData || []);
        }
    }, [sessionDetails])

    useEffect(() => {
        dispatch(studentSessionDetails({PageSize: 10, Page: 1, CallType: sessionType !== '0' ? sessionType : '', FilterID: Number(sessionDay)}))
        return () => {
            dispatch(studentSessionDetailsSuccess({Data: {}}))
        }
    }, [])

  return (
    <div>
        <Header/>
       
       <Box className="quikCallBanner">

       <Box className='OuterContainer' sx={{border:'none', borderRadius:'0px'}}>
            <Box className="particalsBanner">
            <Typography variant='div' className='Partical1'></Typography>
            <Typography variant='div' className='yellowStar'>
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.3287 8.00239C12.4637 7.62496 11.7674 6.94205 11.3724 6.08364C10.9773 5.22523 10.9111 4.25125 11.1864 3.34713C11.2309 3.20852 11.259 3.06513 11.27 2.91993C11.2952 2.50569 11.1865 2.09435 10.9601 1.74683C10.7336 1.3993 10.4015 1.13409 10.0129 0.99046C9.62422 0.84683 9.19973 0.832424 8.80228 0.949385C8.40483 1.06635 8.05552 1.30844 7.80615 1.63982C7.72039 1.75739 7.64883 1.88469 7.59285 2.01906C7.21611 2.88569 6.53444 3.58329 5.67761 3.97905C4.82077 4.37482 3.84854 4.4411 2.94607 4.16533C2.80772 4.1207 2.66459 4.0926 2.51966 4.08158C2.10619 4.05637 1.69562 4.16527 1.34874 4.39211C1.00186 4.61895 0.737118 4.95167 0.593753 5.34102C0.450387 5.73038 0.436016 6.15565 0.552753 6.55383C0.66949 6.95201 0.911133 7.30191 1.2419 7.55175C1.35925 7.63767 1.48633 7.7094 1.62047 7.76548C2.48551 8.14292 3.18181 8.82586 3.57684 9.68426C3.97188 10.5427 4.03807 11.5166 3.76281 12.4207C3.71826 12.5594 3.69022 12.7027 3.67921 12.8479C3.65405 13.2622 3.76271 13.6735 3.98914 14.021C4.21557 14.3686 4.54771 14.6338 4.93635 14.7774C5.325 14.921 5.74948 14.9354 6.14693 14.8185C6.54438 14.7015 6.89363 14.4594 7.143 14.128C7.22876 14.0104 7.30039 13.8832 7.35637 13.7488C7.73311 12.8821 8.41477 12.1846 9.27161 11.7888C10.1284 11.3931 11.1006 11.3267 12.0031 11.6025C12.1414 11.6471 12.2846 11.6752 12.4296 11.6863C12.843 11.7115 13.2536 11.6026 13.6005 11.3758C13.9474 11.149 14.2121 10.8162 14.3555 10.4268C14.4988 10.0375 14.5132 9.61223 14.3965 9.21404C14.2797 8.81586 14.0381 8.46591 13.7073 8.21609C13.59 8.1302 13.4629 8.05848 13.3287 8.00239Z" fill="#FFB800"/>
                </svg>
            </Typography>
            <Typography variant='div' className='redStar'>
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M13.4283 8.10786C12.5633 7.73043 11.867 7.04751 11.4719 6.1891C11.0769 5.33069 11.0107 4.35672 11.286 3.45259C11.3305 3.31398 11.3586 3.17059 11.3696 3.0254C11.3948 2.61116 11.2861 2.19982 11.0597 1.8523C10.8332 1.50477 10.5011 1.23956 10.1125 1.09593C9.72382 0.952299 9.29933 0.937893 8.90188 1.05485C8.50443 1.17181 8.15516 1.41391 7.90578 1.74529C7.82003 1.86286 7.7484 1.99015 7.69242 2.12453C7.31568 2.99116 6.63402 3.68876 5.77718 4.08452C4.92034 4.48028 3.94818 4.54657 3.04571 4.2708C2.90735 4.22617 2.7642 4.19806 2.61927 4.18705C2.20579 4.16184 1.79523 4.27073 1.44835 4.49757C1.10147 4.72441 0.836727 5.05714 0.693362 5.44649C0.549996 5.83584 0.535625 6.26111 0.652362 6.65929C0.769099 7.05747 1.01074 7.40738 1.3415 7.65721C1.45886 7.74313 1.58594 7.81487 1.72008 7.87094C2.58511 8.24838 3.28141 8.93132 3.67645 9.78972C4.07148 10.6481 4.13768 11.6221 3.86242 12.5262C3.81787 12.6648 3.78979 12.8082 3.77879 12.9534C3.75362 13.3676 3.86229 13.779 4.08871 14.1265C4.31514 14.474 4.64728 14.7392 5.03592 14.8829C5.42457 15.0265 5.84905 15.0409 6.2465 14.9239C6.64396 14.807 6.99323 14.5649 7.2426 14.2335C7.32836 14.1159 7.39999 13.9886 7.45597 13.8542C7.83271 12.9876 8.51437 12.29 9.37121 11.8943C10.228 11.4985 11.2002 11.4322 12.1027 11.708C12.241 11.7526 12.3842 11.7807 12.5291 11.7917C12.9426 11.8169 13.3532 11.7081 13.7001 11.4813C14.047 11.2544 14.3117 10.9217 14.4551 10.5323C14.5985 10.1429 14.6128 9.71769 14.4961 9.3195C14.3793 8.92132 14.1377 8.57138 13.8069 8.32155C13.6895 8.23566 13.5625 8.16394 13.4283 8.10786Z" fill="#EA4435"/>
                </svg>
            </Typography>

            <Typography variant='div' className='Partical2'></Typography>

            <Typography variant='h1'>
                {translation?.Hi} {profileInfo?.UserName}, <Typography variant='div'> {translation?.WelcomeYasa}</Typography>
            </Typography>
            </Box>
        </Box>
       </Box>
        
   

        <Box className='OuterContainer containerShape' sx={{background:'#EAF4FF', padding:'30px'}}>
            <Box className='SectionIn'>
                <Box className="BackBtn">
                    <Button className='backBtn' variant="text" sx={{mb:2}}  startIcon={localStorage.getItem("I18N_LANGUAGE") === "en" ? <ArrowBackIcon /> : <ArrowForward />} onClick={handleBack}>{translation?.BackBtn}</Button>
                </Box>
                <Box className="filterBox" sx={{mb:5}}>
                    <Box className="filterhead" sx={{gap:2, display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                        <Typography variant='h2' sx={{fontSize:'20px', color:'#0F4D92', fontWeight:'600', lineHeight:'28px', textTransform:'capitalize'}}>
                            {translation?.RecentSession}
                        </Typography>
                        <Box className="fillterDropDown" sx={{gap:2, display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between'}}}>
                        
                        <FormControl fullwidth>
                            <Select
                                labelId="demo-simple-select-label" 
                                id="demo-simple-select" 
                                value={sessionType}
                                onChange={handleSessionTypeChange}>
                            <MenuItem value="0">{translation?.SelectYourSessionType}</MenuItem>
                            {sessionTypeList.map((item) => {
                                return <MenuItem value={item.Type} key={item.Type}>{t(item.Value)}</MenuItem>
                            })}
                            </Select>
                        </FormControl>

                        <FormControl fullwidth>
                            <Select
                                labelId="demo-simple-select-label" 
                                id="demo-simple-select-day" 
                                value={sessionDay}
                                onChange={handleSessionDayChange}>
                            <MenuItem value="0">{translation?.SelectYourSessionDay}</MenuItem>
                            {sessionDayList.map((item, index) => {
                                return <MenuItem value={item?.ID} key={index}>{t(item?.Value)}</MenuItem>
                            })}
                            </Select>
                        </FormControl>
                      
                        </Box>
                    </Box>
                </Box>

                <Box className="videoSection">
                    <Box sx={{ flexGrow: 1 }}>
                        {isApiCalling ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
                            <CircularProgress />
                        </Box>
                        ) : sessionList.length === 0 ? (
                        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: '600', mb: 2 }}>
                            {translation?.NoSessionFound}
                        </Box>
                        ) : (
                        <Grid container spacing={2}>
                            {sessionList.map((session) => (
                            <Grid item xs={12} md={6} key={session?.ID}>
                                <Box className="outerCard">
                                <Box className="VideoBox">
                                    {session?.RecordedSessionURL ? (
                                    <VideoThumbnail videoUrl={session?.RecordedSessionURL} snapshotAtTime={20} />
                                    ) : (
                                    <Box className="VideoMsg">
                                        {translation?.VideoWillBeAvailable}
                                    </Box>
                                    )}
                                    {session?.RecordedSessionURL && (
                                    <Box className="playBtn" onClick={() => onSessionVideoClick(session)}>
                                        <svg width="13" height="14" viewBox="0 0 13 14" style={{marginLeft:'2px'}} fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11.3861 5.24298C12.7788 6.00033 12.7788 7.99967 11.3861 8.75702L3.2393 13.1871C1.90661 13.9118 0.283854 12.9471 0.283854 11.4301L0.283854 2.56991C0.283854 1.05293 1.90661 0.0881979 3.23929 0.81289L11.3861 5.24298Z" fill="white" />
                                        </svg>
                                    </Box>
                                    )}
                                    <Box className="videoInBts" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                    <button className="hireBtn">
                                        {session?.Type === 'PT' ? translation?.FindTutor : session?.Type === 'QC' ? translation?.QuickCallBtn : ''}
                                    </button>
                                    <button className="timerBtn">
                                        {formatCallDuration(session?.CallDuration)}
                                    </button>
                                    </Box>
                                </Box>
                                <Box className="contentBoxList">
                                    <Box className="TimeRankBox" sx={{ display:{xs: 'flex', alignItems: 'center', justifyContent:'space-between', minHeight: '37px'}}}>
                                        <Box className="dateTimeBox" sx={{gap:1, display:{xs: 'flex', alignItems: 'center'}}}>
                                            <Typography variant='div'>{timeAgoDate(session?.StartDateTime)}</Typography>
                                            {/* <Typography variant='div'>{timeAgoDate(session?.StartDateTime)}</Typography>|
                                            <Typography variant='div'>{momentDate(session?.StartDateTime)}</Typography> */}
                                        </Box>
                                        <Box className="RatingBox">
                                        { parseFloat(session?.Ratings) ?
                                            <Stack spacing={2}>
                                                <Rating sx={{direction: 'ltr'}} name="half-rating-read" value={parseFloat(session?.Ratings)} precision={0.5} readOnly />
                                            </Stack> : 
                                            <Box className="cardLeft">
                                                <Button onClick={() => handleSessionRating(session)}>{translation?.RateThisSession}</Button>
                                            </Box>
                                        }
                                        </Box>
                                    </Box>
                                    <Box className="mathsBtn" sx={{minHeight: '31px'}}>
                                        {session?.Type === 'QC' && <button className='sky radius4'>{session?.TopicName}</button>}
                                    </Box>
                                    {session?.Notes && (
                                        <>
                                        {isHTML(session.Notes) ? (
                                            // Render HTML if it contains valid HTML
                                            <Typography variant='h5' sx={{ minHeight: '20px'}}>
                                            </Typography>
                                            ) : (
                                            <Typography variant='h5' sx={{ minHeight: '20px'}}>
                                                {session?.Notes?.length > 30 ?  session?.Notes.slice(0, 30) + '...' : session?.Notes}
                                            </Typography>
                                            )}
                                        </>
                                    )}
                                    {/* <Typography variant='h5' sx={{ minHeight: '20px'}}>
                                        {session?.Notes?.length > 30 ?  session?.Notes.slice(0, 30) + '...' : session?.Notes}
                                    </Typography> */}
                                    <Box className="sessionCard radius8" sx={{mt:1}}>
                                        <Typography variant='h5' sx={{mb:1}}>{translation?.SessionBy}</Typography>
                                        <Box className="RankInfo" sx={{gap:1.5, display:{xs: 'flex', alignItems: 'top'}}}>
                                            <Box 
                                                sx={{cursor: 'pointer'}}
                                                className={`ProfilePic ${session.OnlineStatusID === '1' ? 'add-online' : session.OnlineStatusID === '2' ? 'add-busy' : 'add-offline' }`}
                                                onClick={() => viewTutorProfile(session?.TutorID)}>
                                                <img src={session?.TutorProfilePicture}></img>
                                            </Box>
                                            <Box className="TeachInfo">
                                                <Typography variant='h5'>{session?.TutorName}</Typography>
                                                <Box className="RatingBox">
                                                    <Stack spacing={2}>
                                                        <Rating sx={{direction: 'ltr'}} name="half-rating-read" value={parseFloat(session?.TutorRating) || 0} precision={0.5} readOnly />
                                                    </Stack>
                                                </Box> 
                                            </Box>
                                            <Button className={`favrouiteBtn ${session?.FavouriteTutor === "true" ? 'active' : ''}`} onClick={() => handleFavouriteTutor(session)} sx={{padding: '0', minWidth: '36px', maxWidth: '36px', background: 'none', marginLeft:'auto'}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <circle cx="12" cy="12" r="12" fill="#D8D8D8" />
                                                    <path d="M12 4L13.7961 9.52786H19.6085L14.9062 12.9443L16.7023 18.4721L12 15.0557L7.29772 18.4721L9.09383 12.9443L4.39155 9.52786H10.2039L12 4Z" fill="white" />
                                                </svg>
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                                </Box>
                            </Grid>
                            ))}
                        </Grid>
                        )}
                    </Box>
                </Box>

                { !isApiCalling &&
                    <Box className="PaginationBox" sx={{ mt:4, mb:5, display:{xs: 'flex', alignItems: 'center', justifyContent:'flex-start'}}}>
                        <Stack spacing={2}>
                            <Pagination defaultPage={1} page={page} count={sessionDetails?.totalPages}  onChange={handleChange} variant="outlined" color="primary" />
                        </Stack>
                    </Box>
                }
                <Advertisement />
                </Box>
            </Box>
            <FeedbackModal isOpen={feedbackModal} feedback={sessionFeedBack} translation={translation} onChangeFeedback={onChangeFeedback} onSubmitFeedback={onSubmitFeedback} />
            <FooterSection />
        </div>
    )
}

export default SessionList