import React, { useState, useEffect } from 'react'
import { Box, Button, CircularProgress, Pagination, Typography } from '@mui/material'
import Cookies from 'js-cookie';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForward from '@mui/icons-material/ArrowForward';
import Paper from '@mui/material/Paper';
import TableContainer from '@mui/material/TableContainer';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

import Header from "../header/Header";
import FooterSection from '../footer/FooterSection'

import hire_tut from '../../assets/images/hire_tut.svg'
import blue_str from '../../assets/images/blue_star.svg'

import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import useTranslations from '../../custom-hooks/useTranslations';
import { getTransactions } from '../../actions/getTransactionsAction';
import { dateFilter } from '../../services/dateFilter';
import { getStudentProfile } from '../../actions/studentProfileAction';

import './Wallet.css';

const paymentTypeList = [
    {ID: 1, Value: "Debit"},
    {ID: 2, Value: "Credit"}
]

const Wallet = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [date, setDate] = useState('0');
    const [type, setType] = useState('0');
    const translations = useTranslations();
    const [paymentType, setPaymentType] = useState('0');
    const [filterValue, setFilterValue] = useState('0');
    const [filterOption, setFilterOption] = useState([]);
    const [transactions, setTransactions] = useState([]);
    const [filteredTransactions, setFilteredTransactions] = useState([]);
    const [debitArr, setDebitArr] = useState([]);
    const [creditArr, setCreditArr] = useState([]);
    const [spend, setSpend] = useState({TotalSpend: 0, FilterSpend: 0});
    const [page, setPage] = useState(1); 
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const profileInfo = Cookies.get("ProfileInfo") ? JSON.parse(Cookies.get("ProfileInfo")): null;
    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const transactionsData = useSelector(({ getTransactionsReducer }) => getTransactionsReducer?.transactions) || {};
    

    const translation = {
        ...translations[3]?.MenuScreen,
        ...translations[21]?.CommonFrontend,
        ...translations[26]?.QuickCall,
        ...translations[31]?.Wallet
    }

    const handlePaginationChange = (event, value) => {
        setPage(value);
    };

    const handleBack = () => {
        navigate(-1);
    }

    const handleDateChange = (event) => {
        const { value } = event.target;
        setDate(value);
        filterTransactions(value, paymentType, filterValue)
    };

    const handlePaymentTypeChange = (event) => {
        const { value } = event.target;
        if (value.toString() === '1') {
            setFilterOption(transactionsData?.Sorting?.DebitFilter)
        } else if (value.toString() === '2') {
            setFilterOption(transactionsData?.Sorting?.CreditFilter)
        }
        setPaymentType(value);
        setFilterValue('0');
        filterTransactions(date, value, '0')
    } 

    const handleFilterValueChange = (event) => {
        const { value } = event.target;
        setFilterValue(value);
        filterTransactions(date, paymentType, value)
    }

    const filterTransactions = (Date, PaymentType, FilterValue) => {
        let filteredTransactions, filterSpend;
        const dateFilteredTransactions = dateFilter(transactionsData?.HistoryList, Date.toString())
        if (PaymentType.toString() === '1') {
            filteredTransactions = dateFilteredTransactions.filter((item) => debitArr.includes(item.Type.toString()));
        } else if (PaymentType.toString() === '2') {
            filteredTransactions = dateFilteredTransactions.filter((item) => creditArr.includes(item.Type.toString()));
        } else {
            filteredTransactions = dateFilteredTransactions;
        }
        const updatedTransactions = filteredTransactions?.filter((item) => FilterValue.toString() === '0' || item.Type.toString() === FilterValue.toString())

        if (PaymentType.toString() === '1' || PaymentType.toString() === '0') {
            filterSpend = updatedTransactions.reduce((acc, item) => 
                !item.IsCredit && debitArr.includes(item.Type.toString()) 
                    ? acc + parseFloat(item.Amount) 
                    : acc, 
                0
            );
        } else {
            filterSpend = updatedTransactions.reduce((acc, item) => 
                item.IsCredit && creditArr.includes(item.Type.toString()) 
                ? acc + parseFloat(item.Amount) 
                : acc, 
                0
            );
        }
        setFilteredTransactions(updatedTransactions); 
        setTransactions(
            updatedTransactions?.slice(
                (page - 1) * rowsPerPage,
                page * rowsPerPage
        ))

        setSpend((prev) => ({
            ...prev,
            FilterSpend: filterSpend
        }));
    };

    useEffect(() => {
        if (Object.keys(transactionsData).length > 0) {
            const debitIds = transactionsData.Sorting.DebitFilter.map(item => item.ID)
            setDebitArr(transactionsData.Sorting.DebitFilter.map(item => item.ID));
            setCreditArr(transactionsData.Sorting.CreditFilter.map(item => item.ID));
            
            const spend = transactionsData?.HistoryList.reduce((acc, item) => 
                !item.IsCredit && debitIds.includes(item.Type.toString()) 
                    ? acc + parseFloat(item.Amount) 
                    : acc, 
                0
            );
    
            const typeArr = [...transactionsData.Sorting.DebitFilter, ...transactionsData.Sorting.CreditFilter];
            const typeObj = typeArr.reduce((acc, current) => {
                acc[current.ID] = current.Value;
                return acc;
            }, {});
    
            
            setTransactions(
                transactionsData.HistoryList?.slice(
                    (page - 1) * rowsPerPage,
                    page * rowsPerPage
            ))
            setSpend({ TotalSpend: spend, FilterSpend: spend });
            setType(typeObj);
        }
    }, [transactionsData]);

    useEffect(() => {
        if (Object.keys(transactionsData).length > 0) {
            filterTransactions(date, paymentType, filterValue);
        }
    }, [transactionsData, page, date, paymentType, filterValue]); 

    useEffect(() => {
        dispatch(getTransactions());
        dispatch(getStudentProfile());

    }, [dispatch])

    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
            backgroundColor: theme.palette.common.black,
            color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
            fontSize: 14,
        },
    }));

    const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
            border: 0,
        },
    }));


    

    return (
        <>
            <Header />
            <Box className="quikCallBanner">
                <Box className='OuterContainer' sx={{border:'none', borderRadius:'0px'}}>
                    <Typography variant='h1'>
                        {translation?.Hi} {profileInfo?.UserName}, <Typography variant='div'> {translation?.WelcomeYasa}</Typography>
                    </Typography>
                </Box>
            </Box>
            <Box className='OuterContainer containerShape' sx={{ background: '#EAF4FF', padding: '30px' }}>
                {!transactionsData ? <Box className="outerCard oCenter" sx={{ display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress />
                 </Box> : 
                    <Box className='outerCard'>
                        <Box className="SectionIn">
                            <Box className="BackBtn">
                                <Button className='backBtn' variant="text" sx={{ mb: 1, paddingLeft: 0 }} startIcon={localStorage.getItem("I18N_LANGUAGE") === "en" ? <ArrowBackIcon /> : <ArrowForward />} onClick={handleBack}>
                                    {translation?.BackBtn}
                                </Button>
                            </Box>
                        </Box>
                        <Box className='c_box' sx={{ backgroundColor: '#EAF4FF', marginBottom: '30px' }}>
                            <Typography sx={{ fontSize: '18px', fontWeight: '600', lineHeight: '1.2', color: '#000', marginBottom: '16px', textTransform: 'capitalize' }}>{translation?.PaymentTransaction}</Typography>
                            <Box className="inr_wht_wrap">
                                <Box className='inr_wht_box'>
                                    <Box className="icon_wrap lt_blue">
                                        <img src={hire_tut} />
                                    </Box>
                                    <Box>
                                        <Typography sx={{ fontSize: '18px', fontWeight: '500', lineHeight: '1.2', marginBottom: '4px', color: '#000' }}>{translation?.Balance}</Typography>
                                        <Typography sx={{ fontSize: '18px', fontWeight: '500', lineHeight: '1.2', marginBottom: '4px', color: '#005DB8' }}>{profileInfo?.WalletAmount} {translation?.KuwaitiDinar}</Typography>
                                    </Box>
                                </Box>
                                <Box className='inr_wht_box'>
                                    <Box className="icon_wrap lt_blue">
                                        <img src={blue_str} />
                                    </Box>
                                    <Box>
                                        <Typography sx={{ fontSize: '18px', fontWeight: '500', lineHeight: '1.2', marginBottom: '4px', color: '#000' }}>{translation?.Spend}</Typography>
                                        <Typography sx={{ fontSize: '18px', fontWeight: '500', lineHeight: '1.2', marginBottom: '4px', color: '#005DB8' }}>{spend?.TotalSpend?.toFixed(3)} {translation?.KuwaitiDinar}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                            {/* <Box className="bl_wrapper">
                                <Button className="blue_btn">withdraw</Button>
                            </Box> */}
                        </Box>
                        <Box className="payment_details">
                            <Typography sx={{ fontSize: '18px', fontWeight: '500', lineHeight: '1.2', color: '#000', marginBottom: '16px', textTransform: 'capitalize' }}>{translation?.PaymentDetails}</Typography>
                            <Box className="filterHead">
                                <Box>
                                    <FormControl sx={{marginRight: '10px'}}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={date}
                                            onChange={handleDateChange}
                                        >
                                            <MenuItem value="0" disabled>
                                                {translation?.SelectDate}
                                            </MenuItem>
                                            { transactionsData?.Sorting?.DateFilter?.map((date) => {
                                                return <MenuItem value={date?.ID} key={date?.ID}>{date?.Value}</MenuItem>
                                            }) }
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{marginRight: '10px'}}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={paymentType}
                                            onChange={handlePaymentTypeChange}
                                        >
                                            <MenuItem value="0" disabled>
                                                {translation?.SelectType}
                                            </MenuItem>
                                            { paymentTypeList?.map((item) => {
                                                return <MenuItem value={item?.ID} key={item?.ID}>{translation[item?.Value]}</MenuItem>
                                            }) }
                                        </Select>
                                    </FormControl>
                                    <FormControl sx={{marginRight: '10px'}}>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={filterValue}
                                            onChange={handleFilterValueChange}
                                        >
                                            <MenuItem value="0" disabled>
                                                {translation?.Select}
                                            </MenuItem>
                                            { filterOption?.map((item) => {
                                                return <MenuItem value={item?.ID} key={item?.ID}>{item?.Value}</MenuItem>
                                            }) }
                                        </Select>
                                    </FormControl>
                                </Box>
                                <Typography sx={{ fontSize: '18px', fontWeight: '500', lineHeight: '1', color: '#005DB8' }}>{spend?.FilterSpend?.toFixed(3)} KD</Typography>
                            </Box>
                            <TableContainer className="payment_table" component={Paper}>
                                <Table  aria-label="customized table">
                                    <TableHead>
                                        <TableRow>
                                            <StyledTableCell>{translation?.Icon}</StyledTableCell>
                                            <StyledTableCell>{translation?.TransactionID}</StyledTableCell>
                                            <StyledTableCell>{translation?.Type}</StyledTableCell>
                                            <StyledTableCell>{translation?.Date}</StyledTableCell>
                                            <StyledTableCell>{translation?.Amount}</StyledTableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {transactions?.map((item) => (
                                            <StyledTableRow key={item.TransactionID}>
                                                <StyledTableCell component="th" scope="row"><img src={item.Icon} alt="icon" /></StyledTableCell>
                                                <StyledTableCell>{item.TransactionID}</StyledTableCell>
                                                <StyledTableCell>{type[item.Type]}</StyledTableCell>
                                                <StyledTableCell>{new Date(item.DateTime).toLocaleString('en-GB', { timeZone: userTimeZone })}</StyledTableCell>
                                                <StyledTableCell>{item.IsCredit ? '+' : '-'} {item.Amount} {item.Currency}</StyledTableCell>
                                            </StyledTableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '16px' }}>
                                    <Pagination
                                        count={Math.ceil((filteredTransactions.length || 0) / rowsPerPage)} // Use filteredTransactions length
                                        page={page}
                                        onChange={handlePaginationChange}
                                        variant="outlined"
                                        color="primary"
                                    />
                            </Box>
                        </Box>
                    </Box>  
                 }
            </Box>
            <FooterSection />
        </>
    )
}

export default Wallet