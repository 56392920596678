import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import Cookies from 'js-cookie';
// import './SingleSignon.css';
import '../no-page/NoPage.css';

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import useSocket from "../../custom-hooks/useSocket";
import useTranslations from "../../custom-hooks/useTranslations";
import { getStudentProfile } from "../../actions/studentProfileAction";
import { getStudentDashBoard } from "../../actions/studentDashboardAction";

import { API_BASE_URL } from "../../config/apiConfig";
import { axiosPost } from "../../services/api";
import { generateDeviceId } from "../../services/common";

import multiDevice from "../../assets/images/multi-device-login.svg"
import footerCloud from "../../assets/images/footer-cloud.png"

function SingleSignon() {
	const navigate = useNavigate();
  const dispatch = useDispatch();
  const { socket } = useSocket();
	const translations = useTranslations();
  const [isApiCalling, setIsApiCalling] = useState(false);

	const translation = translations[0]?.Login;
  const userInfo = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : null;

	const activateHere = ()=>{
    setIsApiCalling(true);
    const deviceId = generateDeviceId();
    // sessionStorage.setItem("deviceId", deviceId)
    Cookies.set("deviceId",deviceId , { expires: 25 });

    const data = {
      UserUID: userInfo?.uid,
      Maker: navigator.platform,
      Model: navigator.userAgent.split(" ")[0],
      DeviceID: deviceId,
      Platform: "Web",
      AppVersion: "1.0",
    };
    axiosPost(`${API_BASE_URL}/SaveLoginSession`, data)
      .then((res) => {
        setIsApiCalling(false);
        const customToken  = res.data.Token;
        sessionStorage.removeItem('IsActive');
        localStorage.setItem('customToken', customToken);

        const mySocketData = {
          AuthToken: customToken,
          authorization: JSON.parse(Cookies.get('loginToken')).idToken,
          DeviceID: deviceId,
          IsActive: "true"
        }
        console.log('mySocketData: ', mySocketData);
        socket.emit("LoginSession", JSON.stringify(mySocketData));

        const payload = {
          authorization: JSON.parse(Cookies.get('loginToken')).idToken,
          DeviceID: deviceId,
        }
        socket.emit('StudentOnline', JSON.stringify(payload));
        dispatch(getStudentProfile());
        dispatch(getStudentDashBoard());
        navigate("/");
      })
      .catch((err) => {
        console.log('err: ', err);
      });
	}

  useEffect(() => {
    const handleBeforeUnload = (event) => {
      event.preventDefault();
      event.returnValue = false;
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <div className="mul-dev">
      <div className="mul-dev-col">
        <img src={multiDevice} alt="devices" style={{marginBottom: '20px', width: '250px'}}/>
        <h2 className="black-text">{translation?.MultipleDevicesInform}</h2>
        <p>{translation?.MultipleDevicesInformation}</p>
        <Button type="button" className="mul-btn" onClick={()=>activateHere()} disabled={isApiCalling}>{translation?.ActivateHere}</Button>
	    </div>
      {/* <img className="footer-cloud" src={footerCloud} alt="cloud"/> */}
    </div>
  )
}

export default SingleSignon