import * as actionTypes from "./actionTypes";
import * as apiCall from "../services/api";
import { API_BASE_URL } from "../config/apiConfig";

const isObjectEmpty = (objectName) => {
  return Object.keys(objectName).length === 0
}

export const getCoursessSuccess = (data) => {
  const noClasses = isObjectEmpty(data.Data)
  return {
    type: actionTypes.GET_COURSES,
    payload: {
      courses: noClasses ? [] : data.Data,
    },
  };
};

export const getCourses = () => async (dispatch) => {
  return await apiCall
    .axiosGet(`${API_BASE_URL}/GetGroupCourses`)
    .then((response) => {
      if (response) {
        dispatch(getCoursessSuccess(response.data));
      }
    })
    .catch((error) => {
      console.log('Error: ', error);
    });
};
