import * as actionTypes from "../actions/actionTypes";

const initialState = [];
const GetGroupClassScheduleReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_GROUP_CLASS:
      return {
        groupClass: action.payload.groupClass,
      };

    default:
      return state;
  }
};
export default GetGroupClassScheduleReducer;
