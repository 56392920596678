export const launchAgoraClassroom = async (params) => {

  function loadScript() {
    return new Promise((resolve, reject) => {
      const url = process.env.REACT_APP_AGORA_URL
      if (document.querySelector(`script[src="${url}"]`)) {
        resolve(); // Script already loaded
        return;
      }
      const script = document.createElement("script");
      script.src = url;
      script.async = true;
      script.onload = () => resolve();
      script.onerror = () => reject(new Error(`Failed to load script: ${url}`));
      document.head.appendChild(script);
    });
  }
  
  try {


    await loadScript();
    console.log("Agora SDK loaded successfully.");

    if (!window.AgoraEduSDK) {
      throw new Error("AgoraEduSDK is undefined after script load.");
    }

    const {
      userUid,
      userName,
      roomUuid,
      sessionId,
      roomName,
      rtmToken,
      roomType,
      sessionType,
      pathName,
      agoraSessionEnd,
      agoraConfiguration,
      agoraFreeGroupCallAttendence,
      agoraCallDuration,
    } = params;

    const language = localStorage.getItem("I18N_LANGUAGE") || "en";
    document.body.dir = language === "ar" ? "rtl" : "ltr";

    // Configure the SDK
    window.AgoraEduSDK.config({
      appId: process.env.REACT_APP_AGORA_APP_ID,
      region: process.env.REACT_APP_AGORA_REGION,
    });

    console.log("Agora SDK configured successfully.");

    // Launch the classroom
    window.AgoraEduSDK.launch(document.querySelector("#root"), {
      userUuid: userUid,
      userName,
      roomUuid,
      roleType: 2, // Role type: 1 for teachers, 2 for students.
      roomType,
      roomSubType: 0, // Default room subtype
      roomName,
      pretest: true,
      rtmToken,
      language,
      duration: Number(agoraCallDuration) || 60,
      recordUrl:
        "https://solutions-apaas.agora.io/apaas/record/dev/2.8.13/record_page.html",
      courseWareList: [],
      virtualBackgroundImages: [],
      webrtcExtensionBaseUrl: "https://solutions-apaas.agora.io/static",
      uiMode: "light",
      listener: async (evt, args) => {
        console.log("evt, args:", evt, args);
        if (evt === 201 && args === 1) {
          if (pathName === "/free-group-classes") {
            await agoraFreeGroupCallAttendence(sessionId);
          } else {
            if (agoraConfiguration) {
              await agoraConfiguration(
                userName,
                roomType,
                sessionId,
                sessionType
              );
            }
          }
        }

        if (evt === 2) {
          const currentPathName =
            sessionStorage.getItem("pathName") || pathName;

          if (
            currentPathName === "/login" ||
            currentPathName === "/free-group-classes"
          ) {
            window.location.pathname = currentPathName;
          } else {
            await agoraSessionEnd(
              sessionType,
              sessionId,
              currentPathName,
              userUid,
              roomUuid,
              rtmToken
            );
          }
        }
      },
    });
    console.log("Agora classroom launched successfully.");
  } catch (error) {
    console.error("Failed to load or initialize Agora SDK:", error);
  }
};
