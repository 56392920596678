import React from "react";
import './Payment.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function Payment() {
  const queryParams = new URLSearchParams(window.location.search);
  const resErrorNo = queryParams.get("ResErrorNo");
  const resErrorText = queryParams.get("ResErrorText");
  const paymentID = queryParams.get("paymentid");
  const ref = queryParams.get("ref");
  const postDate = queryParams.get("postdate");
  const resultCode = queryParams.get("result");
  const amount = queryParams.get("amt");
  const tranID = queryParams.get("tranid");
  const auth = queryParams.get("auth");
  const trackID = queryParams.get("trackid");
  const udf1 = queryParams.get("udf1");
  const udf2 = queryParams.get("udf2");
  const udf3 = queryParams.get("udf3");
  
  return (
    <div className="transaction-details-table">
      <div className="container">
        <h2>YASA-KNET Transaction Details</h2>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 320}} aria-label="simple table">
            <TableBody>
            {resErrorNo != null || resErrorText != null
                ? `
                <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell component="th" scope="row">Error - ErrorText</TableCell>
                  <TableCell align="right">${resErrorNo} - ${resErrorText}</TableCell>
                </TableRow>
              `
                : ""}
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Payment ID</TableCell>
                <TableCell align="right">{paymentID}</TableCell>
              </TableRow>
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Reference ID</TableCell>
                <TableCell align="right">{ref}</TableCell>
              </TableRow>
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Post Date</TableCell>
                <TableCell align="right">{postDate}</TableCell>
              </TableRow>
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Result Code</TableCell>
                <TableCell align="right">{resultCode}</TableCell>
              </TableRow>
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Amount</TableCell>
                <TableCell align="right">{amount}</TableCell>
              </TableRow>
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Transaction ID</TableCell>
                <TableCell align="right">{tranID}</TableCell>
              </TableRow>
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Auth</TableCell>
                <TableCell align="right">{auth}</TableCell>
              </TableRow>
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">Track ID</TableCell>
                <TableCell align="right">{trackID}</TableCell>
              </TableRow>
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">UDF1</TableCell>
                <TableCell align="right">{udf1}</TableCell>
              </TableRow>
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">UDF2</TableCell>
                <TableCell align="right">{udf2}</TableCell>
              </TableRow>
              <TableRow
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row">UDF3</TableCell>
                <TableCell align="right">{udf3}</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

export default Payment;
