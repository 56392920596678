import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { decryptData } from "../../services/encryptDecrypt";
import { agoraSessionEnd } from "./AgoraCommon";
import useTranslations from "../../custom-hooks/useTranslations";
import Cookies from 'js-cookie';
import { launchAgoraClassroom } from "../../services/Utils/agoraUtils";

const LaunchCourseRoom = () => {
  const translations = useTranslations();
	const translation = translations[33]?.ApplicationConfiguration;
  const agoraCallDuration = translation?.AgoraCallDuration;
  const { encryptClassId, encryptSessionId } = useParams();
  const classId = decryptData(encryptClassId)
  const sessionId = decryptData(encryptSessionId);
  console.log("sessionId/classId",classId,sessionId);
  
  const userInfo = Cookies.get('userInfo') ? JSON.parse(Cookies.get('userInfo')) : null;
  const profileInfo = Cookies.get("ProfileInfo") ? JSON.parse(Cookies.get("ProfileInfo")): null;
  const rtmToken = JSON.parse(localStorage.getItem("rtmToken"));
  const roomName = `groupcourse${classId}${sessionId}`;
  const roomUuid = `groupcourseuuid${classId}${sessionId}`;
  const channel = `channel${classId}${sessionId}`;
  const userUid = userInfo?.uid;
  const roomType = 4;
  const sessionType = 3;
  const userName = profileInfo?.UserName;
  const pathName = "/courses";

  useEffect(() => {
    localStorage.setItem("SessionType", "GC");
    localStorage.setItem("CallType", 4);
    const params = { userUid, userName, roomUuid, sessionId, roomName, channel, rtmToken, roomType, sessionType, pathName, agoraCallDuration, agoraSessionEnd };
    console.log("params",params);
    
    launchAgoraClassroom(params);
  }, []);
  return;
};
export default LaunchCourseRoom;
